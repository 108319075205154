import http from '@/api';
import CONSTANTS from '@/utils/constants';
import {
  getImageUrl,
  getFullName,
  getPrimaryEmail,
  getPrimaryPhone,
  getPrimaryAddress,
  updateValue,
  getAffiliationOnEntityId,
} from '@/utils/helpers';

const getInitialState = () => ({
  currentUser: {},
  currentUserGroupInvites: [],
  currentUserEventInvites: [],
});

export default {
  namespaced: true,
  state: getInitialState,
  getters: {
    getCurrentMinistryAffiliationStatus: (state, getters, rootState, rootGetters) => {
      const currentMinistryId = rootGetters['ministries/getCurrentMinistryId'];
      const currentMinistryAffiliation = state.currentUser?.affiliations?.find((a) => {
        return a.entityId === currentMinistryId;
      });
      return currentMinistryAffiliation?.status || '';
    },
    getCurrentUserId: (state) => state.currentUser.id,
    getCurrentUser: (state) => state.currentUser,
    getCurrentUserFirstName: (state) => state.currentUser?.firstName,
    getCurrentUserName: (state) => getFullName(state.currentUser),
    getCurrentUserImageGuid: (state) => getImageUrl(state.currentUser.imageGuid),
    getCurrentUserImageUrl: (state) => getImageUrl(state.currentUser.imageGuid),
    getCurrentUserEmail: (state) => getPrimaryEmail(state.currentUser),
    getCurrentUserPhone: (state) => getPrimaryPhone(state.currentUser),
    getCurrentUserAddress: (state) => getPrimaryAddress(state.currentUser),
    getCurrentUserJobTitle: (state, getters, rootState, rootGetters) =>
      state.currentUser.affiliations?.find(
        (aff) => aff.entityId === rootGetters['ministries/getCurrentMinistryId']
      )?.title || '',
    getCurrentUserSocialMedia: (state) => state.currentUser?.socialMediaAccounts || [],
    getCurrentUserMinistries: (state) => {
      const { affiliations } = state.currentUser;
      if (!affiliations || !affiliations.length) {
        return [];
      }
      const ministryAffiliations = affiliations.filter(
        (a) => a.entityType === 'CAMPUS_MINISTRY' && a.roleName === 'Campus Minister'
      );
      if (ministryAffiliations.length < 1) {
        return [];
      }

      ministryAffiliations.sort((a, b) => {
        if (!a.entityName) {
          return 1;
        }
        if (!b.entityName) {
          return -1;
        }
        return a.entityName?.localeCompare(b.entityName, 'en', { ignorePunctuation: true });
      });

      return ministryAffiliations.map((affil) => ({
        id: affil.entityId,
        name: affil.entityName,
        status: affil.status,
      }));
    },
    getCurrentUserMinistriesWithColleges: (state, getters, rootState, rootGetters) => {
      const currentUserMinistries = getters.getCurrentUserMinistries;

      return currentUserMinistries.map((ministry) => {
        const collegesByMinistryId = rootGetters['colleges/getCollegesByMinistryId'](ministry.id);
        ministry.collegesName = collegesByMinistryId.map((x) => x.name);
        ministry.collegesId = collegesByMinistryId.map((x) => x.id);
        ministry.totalCollegeCount = rootGetters['pagination/getTotalCollegeCountByMinistry'](
          ministry.id
        );
        return ministry;
      });
    },
    getMinistryAffiliationId: (state) => (ministryId) => {
      let affiliationObj = state.currentUser.affiliations.find((x) => x.entityId === ministryId);
      return affiliationObj.id;
    },
    getCurrentUserAffiliations: (state) => {
      return state.currentUser?.affiliations || [];
    },
    getCurrentUserAffiliationByEntityId: (state) => (entityId) => {
      return getAffiliationOnEntityId(state.currentUser, entityId);
    },
    getCurrentUserGroupInvites: (state) => state.currentUserGroupInvites || [],
    getCurrentUserGroupInvite: (state) => (groupId) => {
      return state.currentUserGroupInvites.find((x) => x.groupId === groupId) || null;
    },
  },
  mutations: {
    SET_INITIAL_STATE: (state) => {
      Object.assign(state, getInitialState());
    },
    SET_CURRENT_USER: (state, value) => {
      state.currentUser = value;
    },
    SET_PARTIAL_UPDATE_TO_AFFILIATIONS: (state, { ministryId, ministryPartial }) => {
      const updateMinistry = state.currentUser.affiliations.find((item) => item.id === ministryId);
      if (updateMinistry && ministryPartial.name) {
        Object.assign(updateMinistry, {
          entityName: ministryPartial.name,
        });
      }
    },
    SET_PARTIAL_UPDATE_TO_USER: (state, { userPartial }) => {
      Object.assign(state.currentUser, { ...state.currentUser, ...userPartial });
    },
    SET_CURRENT_USER_GROUP_INVITES: (state, value) => (state.currentUserGroupInvites = value),
  },
  actions: {
    async fetchCurrentUser({ commit }) {
      try {
        const { data: currentUser } = await http.get('person/active');
        if (!currentUser || Object.keys(currentUser).length === 0) {
          throw new Error(CONSTANTS.ERRORS.CURRENT_USER_NONE);
        }
        commit('SET_CURRENT_USER', currentUser);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async updateCurrentUserPartial({ commit, getters, rootGetters }, { field, fieldValue }) {
      try {
        const currentUserId = getters.getCurrentUserId;
        const currentUser = getters.getCurrentUser;
        const currentMinistryId = rootGetters['ministries/getCurrentMinistryId'];
        const currentUserPartial = updateValue(
          JSON.parse(JSON.stringify(currentUser)),
          field,
          fieldValue
        );
        await http.patch(`person/${currentUserId}`, currentUserPartial);
        commit('SET_PARTIAL_UPDATE_TO_USER', { userPartial: currentUserPartial });
        const staffObj = rootGetters['staffs/getStaffObjById']({
          ministryId: currentMinistryId,
          staffId: currentUserId,
        });
        if (Object.keys(staffObj).length > 0) {
          commit(
            'staffs/SET_PERSON_PARTIAL',
            {
              ministryId: currentMinistryId,
              staffId: currentUserId,
              staffPartial: currentUserPartial,
            },
            { root: true }
          );
        }
      } catch (error) {
        console.log(error);
        throw new Error(CONSTANTS.ERRORS.DATA_UPDATE);
      }
    },
    async updateCurrentUserAffiliation({ commit, getters, rootGetters }, { field, fieldValue }) {
      try {
        const currentUserId = getters.getCurrentUserId;
        const currentUser = getters.getCurrentUser;
        const currentMinistryId = rootGetters['ministries/getCurrentMinistryId'];
        const affiliationObjState = currentUser?.affiliations?.find(
          (aff) => aff.entityId === currentMinistryId
        );
        let affiliationObj = JSON.parse(JSON.stringify(affiliationObjState));
        affiliationObj[field] = fieldValue;
        let allAffiliationsState = currentUser?.affiliations;
        let allAffiliations = JSON.parse(JSON.stringify(allAffiliationsState));
        allAffiliations.find((aff) => aff.entityId === currentMinistryId)[field] = fieldValue;
        console.log(allAffiliations);
        let currentUserPartial = { affiliations: allAffiliations };
        commit('SET_PARTIAL_UPDATE_TO_USER', { userPartial: currentUserPartial });
        await http.put(`affiliation`, affiliationObj);
        const staffObj = rootGetters['staffs/getStaffObjById']({
          ministryId: currentMinistryId,
          staffId: currentUserId,
        });
        if (Object.keys(staffObj).length > 0) {
          commit(
            'staffs/SET_PERSON_PARTIAL',
            {
              ministryId: currentMinistryId,
              staffId: currentUserId,
              staffPartial: currentUserPartial,
            },
            { root: true }
          );
        }
      } catch (error) {
        console.log(error);
        throw new Error(CONSTANTS.ERRORS.DATA_UPDATE);
      }
    },
    async userSignup({}, person) {
      try {
        await http.post('account', person);
      } catch (error) {
        if (error?.response?.status === 409) {
          console.log(`This email is already associated with an account. ${error}`);
          throw new Error('This email is already being used.');
        } else {
          console.log(
            `Something has gone wrong. Please check your input values and try again. ${error}`
          );
          throw new Error('Sorry, something went wrong. Please try again.');
        }
      }
    },
    async checkTokenForPassword({}, token) {
      try {
        const { data } = await http.get(`password/token?token=${token}`);
        if (!data.tokenGood) {
          throw new Error(data.email);
        }
        return data;
      } catch (error) {
        if (error.message.includes('@')) {
          throw error;
        }
        throw new Error('Could not validate token. Rerouting to link-expired page.');
      }
    },
    async savePassword({}, passwordData) {
      try {
        await http.post('password', passwordData);
      } catch (error) {
        throw new Error(CONSTANTS.ERRORS.PASSWORD_RESET);
      }
    },
    async sendPasswordResetEmail({}, payload) {
      try {
        await http.post('password/reset', payload);
      } catch (error) {
        throw new Error('Something has gone wrong. Please check your input value and try again.');
      }
    },
    async addMinistryToCurrentUser({ getters, dispatch }, dataToSend) {
      try {
        let ministryToConnectId = dataToSend.entityId;
        const currentUserMinistries = getters.getCurrentUserMinistries;
        const currentUserMinistriesId = currentUserMinistries.map((ministry) => ministry.id);
        if (currentUserMinistriesId.includes(ministryToConnectId)) {
          throw new Error(CONSTANTS.ERRORS.MINISTRY_EXISTING_AFFILIATION);
        } else {
          // if affiliation does not exist
          await http.post('affiliation/signup', dataToSend);
          await dispatch('fetchCurrentUser');
        }
      } catch (error) {
        // console.error(error);
        throw error;
      }
    },
    async removeMinistryFromCurrentUser({ getters, dispatch }, ministryIdToRemove) {
      try {
        const affiliationId = getters.getMinistryAffiliationId(ministryIdToRemove);
        await http.delete(`affiliation/${affiliationId}`);
        await dispatch('fetchCurrentUser');
      } catch (error) {
        throw error;
      }
    },
    async fetchCurrentUserGroupInvites({ getters, commit }) {
      // NOTE: when adding websocket don't forget un call this function in Groups.vue under onMounted
      try {
        const currentUserId = getters.getCurrentUserId;
        const {
          data: { results: currentUserGroupInvites },
        } = await http.get(
          `group/invite/person/${currentUserId}?search=(active:true)&start=0&count=999`
        );
        // debugger;
        commit('SET_CURRENT_USER_GROUP_INVITES', currentUserGroupInvites);
      } catch (error) {
        throw error;
      }
    },
  },
};
