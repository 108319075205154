import http from '@/api';
import router from '@/router';
import CONSTANTS from '@/utils/constants';
import {
  getImageUrl,
  getFullName,
  getPrimaryEmail,
  getPrimaryPhone,
  updateValue,
  getPrimaryAddress,
  generateApiSearchQuery,
} from '@/utils/helpers';

const getInitialState = () => ({
  currentMinistryId: null,
  ministries: {},
});

export default {
  namespaced: true,
  state: getInitialState,
  getters: {
    getCurrentMinistryImageGuid: (state) =>
      state.ministries[state.currentMinistryId]?.primaryImageGuid,
    getCurrentMinistryImageUrl: (state) =>
      getImageUrl(state.ministries[state.currentMinistryId]?.primaryImageGuid),
    getCurrentMinistrySocialMediaAccounts: (state) =>
      state.ministries[state.currentMinistryId]?.socialMediaAccounts || [],
    getCurrentMinistryWebsite: (state) => state.ministries[state.currentMinistryId]?.website || '',
    getCurrentMinistryAdorationTime: (state) =>
      state.ministries[state.currentMinistryId]?.adorationTime || '',
    getCurrentMinistryConfessionTime: (state) =>
      state.ministries[state.currentMinistryId]?.confessionTime || '',
    getCurrentMinistryMassTime: (state) =>
      state.ministries[state.currentMinistryId]?.massTime || '',
    getCurrentMinistryName: (state) => state.ministries[state.currentMinistryId]?.name || '',
    getCurrentMinistryWelcomeMessage: (state) =>
      state.ministries[state.currentMinistryId]?.welcomeMessage || '',
    getCurrentMinistryEmail: (state) =>
      getPrimaryEmail(state.ministries[state.currentMinistryId]) || '',
    getCurrentMinistryPhone: (state) =>
      getPrimaryPhone(state.ministries[state.currentMinistryId]) || '',
    getCurrentMinistryAddress: (state) => {
      return getPrimaryAddress(state.ministries[state.currentMinistryId]);
    },
    getCurrentMinistryId: (state) => state.currentMinistryId,
    getCurrentMinistry: (state) => state.ministries[state.currentMinistryId] || {},
    getMinistryById: (state) => (ministryId) => state.ministries[ministryId],
    getMinistryIds: (state) => Object.keys(state.ministries),
    getCurrentMinistryRelatedEntityIds: (state) => {
      const currentMinistry = state.ministries[state.currentMinistryId];
      const entityIds = [currentMinistry.id];
      for (const college of currentMinistry.colleges) {
        entityIds.push(college.id);
      }
      return entityIds;
    },
  },
  mutations: {
    SET_INITIAL_STATE: (state) => {
      Object.assign(state, getInitialState());
    },
    SET_CURRENT_MINISTRY_ID: (state, ministryId) => {
      localStorage.setItem('currentMinistryId', ministryId);
      state.currentMinistryId = Number(ministryId);
    },
    SET_MINISTRY: (state, ministry) => {
      state.ministries = { ...state.ministries, [ministry.id]: ministry };
    },
    REMOVE_MINISTRY_BY_MINISTRY_ID: (state, removedMinistryId) => {
      delete state.ministries[removedMinistryId];
    },
    SET_MINISTRY_PARTIAL: (state, { ministryId, ministryPartial }) => {
      const newMinistryObj = { ...state.ministries[ministryId], ...ministryPartial };
      Object.assign(state.ministries, { ...state.ministries, [ministryId]: newMinistryObj });
    },
  },
  actions: {
    async setCurrentMinistryId({ rootGetters, commit }, ministryId = null) {
      try {
        const currentUserMinistry = rootGetters['currentUser/getCurrentUserMinistries'];
        if (ministryId) {
          commit('SET_CURRENT_MINISTRY_ID', ministryId);
        } else if (
          localStorage.getItem('currentMinistryId') &&
          localStorage.getItem('currentMinistryId') !== 'null'
        ) {
          commit('SET_CURRENT_MINISTRY_ID', localStorage.getItem('currentMinistryId'));
        } else if (currentUserMinistry.length > 0) {
          commit('SET_CURRENT_MINISTRY_ID', currentUserMinistry[0].id);
        } else {
          router.push({ name: 'ConnectMinistry' });
          throw new Error(CONSTANTS.ERRORS.MINISTRY_NO_AFFILIATION);
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async resetCurrentMinistryId({ rootGetters, commit, dispatch }) {
      try {
        const currentUserMinistry = rootGetters['currentUser/getCurrentUserMinistries'];

        if (currentUserMinistry.length > 0) {
          commit('SET_CURRENT_MINISTRY_ID', currentUserMinistry[0].id);
          await dispatch('fetchMinistry', currentUserMinistry[0].id);
          await dispatch(
            'staffs/fetchStaffsByMinistryId',
            { ministryId: currentUserMinistry[0].id },
            { root: true }
          );
          await commit('students/SET_INITIAL_STATE', null, { root: true });
          await router.replace({
            name: 'MinistryOverview',
            params: { ministryId: currentUserMinistry[0].id },
          });
        } else {
          localStorage.removeItem('currentMinistryId');
          commit('SET_CURRENT_MINISTRY_ID', null);
          await router.push({ name: 'ConnectMinistry' });
          throw new Error(CONSTANTS.ERRORS.MINISTRY_NO_AFFILIATION);
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async fetchMinistry({ getters, commit }, ministryId) {
      if (getters.getMinistryById(ministryId)) {
        return;
      }
      try {
        const { data: ministry } = await http.get(`campusMinistry/${ministryId}`);
        commit('SET_MINISTRY', ministry);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async createMinistry({}, ministry) {
      try {
        let response = await http.post('campusMinistry', ministry);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async ministriesAutocomplete({}, searchQuery) {
      let apiQuery = generateApiSearchQuery(searchQuery, ['name', 'collegeName']);
      const searchParams = `/campusMinistry?search=${apiQuery}`;
      try {
        const result = await http.get(searchParams);
        return result.data;
      } catch (error) {
        throw error;
      }
    },
    async updateMinistry({ commit }, ministry) {
      try {
        const response = await http.put('campusMinistry', ministry);
        commit('SET_MINISTRY', response.data);
      } catch (e) {
        console.error(e);
      }
    },
    async updateMinistryPartial({ commit, getters }, { field, fieldValue }) {
      try {
        const currentMinistry = getters.getCurrentMinistry;
        const ministryPartial = updateValue(
          JSON.parse(JSON.stringify(currentMinistry)),
          field,
          fieldValue
        );
        await http.patch(`campusMinistry/${currentMinistry.id}`, ministryPartial);
        commit('SET_MINISTRY_PARTIAL', { ministryId: currentMinistry.id, ministryPartial });
        commit(
          'currentUser/SET_PARTIAL_UPDATE_TO_AFFILIATIONS',
          { ministryId: currentMinistry.id, ministryPartial },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        throw new Error(CONSTANTS.ERRORS.DATA_UPDATE);
      }
    },
    async removeMinistry({ getters, commit }, removedMinistryId) {
      try {
        if (getters.getCurrentMinistryId === removedMinistryId) {
          localStorage.removeItem('currentMinistryId');
        }
        commit('REMOVE_MINISTRY_BY_MINISTRY_ID', removedMinistryId);
        commit('colleges/REMOVE_COLLEGE_BY_MINISTRY_ID', removedMinistryId, { root: true });
        commit('staffs/REMOVE_STAFF_BY_MINISTRY_ID', removedMinistryId, { root: true });
        commit('pagination/REMOVE_PAGINATION_BY_MINISTRY_ID', removedMinistryId, { root: true });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
