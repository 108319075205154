import { apiHost } from '@/api';
import store from '@/store';
import router from '@/router';
import dayjs from 'dayjs';
import dayjsUTCPlugin from 'dayjs/plugin/utc';
import dayjsRelativeTimePlugin from 'dayjs/plugin/relativeTime';
import * as getHelpers from '@/utils/getHelpers';

export * from '@/utils/getHelpers';
export * from '@/utils/partialUpdateHelpers';

dayjs.extend(dayjsUTCPlugin);
dayjs.extend(dayjsRelativeTimePlugin);

export const generateUUID = () => {
  let d = new Date().getTime();
  if (Date.now) {
    d = Date.now();
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const wordCount = (str) => {
  return str.split(' ').length;
};

export const phoneValidation = (value) => {
  if (value && value.length) {
    let charArr = value.split('');
    let c = charArr.filter((char) => {
      return char !== ' ' && !isNaN(char);
    });
    if (c.length === 10 || c.length === 11) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const fullNameValidation = (name) => {
  if (name && name.length) {
    let nameArr = name.split(' ');
    let nameArrCleaned = nameArr.filter((n) => {
      return n.trim() !== '';
    });
    if (nameArrCleaned.length === 2) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const ifDayJsValid = (dayJsObj) => (dayJsObj.isValid() ? dayJsObj : undefined);

const dayjsUTC = (dtString) => (dtString ? dayjs.utc(dtString) : dayjs.utc());

export const getDayDiff = (dtString) => {
  const dateNow = dayjs.utc();
  const datePast = dayjs.utc(dtString);
  return dateNow.diff(datePast, 'day', true);
};

export const getRelativeTimeString = (dtString) => {
  if (dtString) {
    return getDayDiff(dtString) < 1 ? 'Today' : dayjsUTC(dtString).fromNow();
  }
  return 'Never';
};

export const maxLengthRequired = (maxLength) => {
  return (value) => {
    if (value && value.length) {
      return getHelpers.getTextLength(value) <= maxLength ? true : false;
    } else {
      return false;
    }
  };
};

export const maxLengthOptional = (maxLength) => {
  return (value) => {
    if (value && value.length) {
      return getHelpers.getTextLength(value) <= maxLength ? true : false;
    } else {
      return true;
    }
  };
};

export const sendToastMessage = (type, message, duration = 5) => {
  const id = generateUUID();
  store.dispatch('toast/addToastMessage', {
    id,
    type,
    message,
    duration,
  });
};

export const populatePathParams = (path, params) => {
  let computed = path;
  Object.entries(params).forEach(([k, v]) => {
    computed = computed.replace(':' + k, v);
  });
  return computed;
};

export const splitFullName = (name) => {
  if (name && name.length) {
    let nameArr = name.split(' ');
    let nameArrCleaned = nameArr.filter((n) => {
      return n.trim() !== '';
    });
    return nameArrCleaned;
  }
};

export const generateApiSearchQuery = (searchString, fields) => {
  let query = ``;
  let searchStringArr = searchString.split(` `).map((el) => {
    return el.toLowerCase();
  });
  searchStringArr.forEach((word, index) => {
    query += `(`;
    fields.forEach((field, index2) => {
      query += `${field}:"${word}"`;
      // START: synonyms
      if (word === 'st.' || word === 'st') {
        query += ` OR ${field}:"saint"`;
      }
      if (word === 'saint') {
        query += ` OR ${field}:"st."`;
      }
      // END: synonyms
      if (index2 + 1 < fields.length) {
        query += ` OR `;
      }
    });
    query += `)`;
    if (index + 1 < searchStringArr.length) {
      query += ` AND `;
    }
  });
  return query;
};

export const generateApiSearchMultiple = (searchStringsArray, field) => {
  let query = '';
  for (const [idx, string] of searchStringsArray.entries()) {
    if (idx + 1 < searchStringsArray.length) {
      query += `${field}:"${string}" OR `;
    } else {
      query += `${field}:"${string}"`;
    }
  }
  return query;
};

export const generateProfileRoute = (personAff, personId) => {
  if (personAff && personId) {
    let routeType = 'student';
    const currentMinistryEntityIds = store.getters['ministries/getCurrentMinistryRelatedEntityIds'];
    for (const entityId of currentMinistryEntityIds) {
      const campusMinister = personAff.find(
        (aff) =>
          (aff.entityId === entityId && aff.roleName?.toLowerCase() === 'campus minister') ||
          aff.roleName?.toLowerCase() === 'app admin'
      );
      if (campusMinister) {
        routeType = 'staff';
      }
    }
    if (routeType === 'staff') {
      // staff
      router.push({ name: 'StaffProfile', params: { staffId: personId } });
    } else {
      // student
      router.push({ name: 'StudentProfile', params: { studentId: personId } });
    }
  }
};

export const formatNumberWithCommas = (num) => {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return num;
  }
};

export const socialMediaIcons = (url, accountType) => {
  const socialMediaListIcons = {
    facebook: 'fa-brands fa-facebook-f',
    instagram: 'fa-brands fa-instagram',
    pinterest: 'fa-brands fa-pinterest',
    snapchat: 'fa-brands fa-snapchat',
    tiktok: 'fa-brands fa-tiktok',
    twitter: 'fa-brands fa-twitter',
    youtube: 'fa-brands fa-youtube',
  };

  // If the user has an account type associated return the icon based on it.
  if (accountType) {
    return socialMediaListIcons[accountType] || 'fa-solid fa-share-nodes';;
  }

  // Otherwise try to find the icon based on the url.
  for (const faIcon of Object.keys(socialMediaListIcons)) {
    if (url.includes(faIcon)) {
      return socialMediaListIcons[faIcon];
    }
  }

  // If no icon was found return the default icon.
  return 'fa-solid fa-share-nodes';
}

export const displayURL = (url) => url.replace(/(^\w+:|^)\/\/(www.)?/, '');