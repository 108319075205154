import http from '@/api';
import {
  getImageUrl,
  getFullName,
  updateValue,
  getPrimaryAddress,
  getPrimaryPhone,
  getPrimaryEmail,
  getRelativeTimeString,
} from '@/utils/helpers';
import CONSTANTS from '@/utils/constants';

const getInitialState = () => ({
  staffs: {},
});

export default {
  namespaced: true,
  state: getInitialState,
  getters: {
    getStaffObjById:
      (state) =>
      ({ ministryId, staffId }) => {
        const staffObj = state.staffs?.[ministryId]?.find((staff) => staff.id === staffId);
        return staffObj || {};
      },
    getAllStaffById:
      (state) =>
      ({ ministryId }) => {
        const staff = state.staffs?.[ministryId];
        return staff || [];
      },
    getStaffImageGuidById:
      (state) =>
      ({ ministryId, staffId }) => {
        const staffObj = state.staffs?.[ministryId]?.find((staff) => staff.id === staffId);
        return staffObj?.imageGuid || '';
      },
    getStaffImageUrlById:
      (state) =>
      ({ ministryId, staffId }) => {
        const staffObj = state.staffs?.[ministryId]?.find((staff) => staff.id === staffId);
        return getImageUrl(staffObj?.imageGuid);
      },
    getStaffNameById:
      (state) =>
      ({ ministryId, staffId }) => {
        const staffObj = state.staffs?.[ministryId]?.find((staff) => staff.id === staffId);
        return getFullName(staffObj) || '';
      },
    getStaffAddressById:
      (state) =>
      ({ ministryId, staffId }) => {
        const staffObj = state.staffs?.[ministryId]?.find((staff) => staff.id === staffId);
        return getPrimaryAddress(staffObj);
      },
    getStaffPhoneById:
      (state) =>
      ({ ministryId, staffId }) => {
        const staffObj = state.staffs?.[ministryId]?.find((staff) => staff.id === staffId);
        return getPrimaryPhone(staffObj) || '';
      },
    getStaffEmailById:
      (state) =>
      ({ ministryId, staffId }) => {
        const staffObj = state.staffs?.[ministryId]?.find((staff) => staff.id === staffId);
        return getPrimaryEmail(staffObj) || '';
      },
    getStaffJobTitleById:
      (state) =>
      ({ ministryId, staffId }) => {
        return (
          state.staffs?.[ministryId]
            ?.find((staff) => staff.id === staffId)
            ?.affiliations?.find((aff) => aff.entityId === ministryId)?.title || ''
        );
      },
    getStaffSocialMediaById:
      (state) =>
      ({ ministryId, staffId }) => {
        const staffObj = state.staffs?.[ministryId]?.find((staff) => staff.id === staffId);
        return staffObj?.socialMediaAccounts || [];
      },
    getStaffHasAccountById:
      (state, getters, rootState, rootGetters) =>
      ({ ministryId, staffId }) => {
        const staffObj = state.staffs?.[ministryId]?.find((staff) => staff.id === staffId);
        return staffObj?.accountHolder;
      },
    getStaffs: (state) => state.staffs || {},
    getStaffsByMinistryId: (state) => (ministryId) => state.staffs[ministryId] || [],
    getStaffsFromCurrentMinistry: (state, getters, rootState, rootGetters) =>
      state.staffs[rootGetters['ministries/getCurrentMinistryId']] || [],
    getStaffListCarouselById: (state) => (ministryId) => {
      const staffList =
        state.staffs[ministryId]?.map((staff) => ({
          id: staff.id,
          imageUrl: getImageUrl(staff.imageGuid),
          fullName: getFullName(staff),
          sortOrder:
            staff.affiliations?.find(
              (aff) => aff.entityId === ministryId && aff.roleName === 'Campus Minister'
            )?.sortOrder || 0,
          role: staff.affiliations.find(
            (aff) => aff.entityId === ministryId && aff.roleName === 'Campus Minister'
          )?.roleName,
          jobTitle:
            staff.affiliations.find(
              (aff) => aff.entityId === ministryId && aff.roleName === 'Campus Minister'
            )?.title || '',
        })) || [];
      return staffList.sort((a, b) => a.sortOrder - b.sortOrder);
    },
    getStaffListById: (state) => (ministryId) => {
      const staffList =
        state.staffs[ministryId]?.map((staff) => ({
          id: staff.id,
          imageUrl: getImageUrl(staff.imageGuid),
          fullName: getFullName(staff),
          role:
            staff.affiliations?.find(
              (aff) => aff.entityId === ministryId && aff.roleName === 'Campus Minister'
            )?.roleName || '',
          email: getPrimaryEmail(staff),
          phone: getPrimaryPhone(staff),
          lastLogin: getRelativeTimeString(staff.lastLogin),
          sortOrder:
            staff.affiliations?.find(
              (aff) => aff.entityId === ministryId && aff.roleName === 'Campus Minister'
            )?.sortOrder || 0,
          affiliationId:
            staff.affiliations?.find(
              (aff) => aff.entityId === ministryId && aff.roleName === 'Campus Minister'
            )?.id || null,
          jobTitle:
            staff.affiliations.find(
              (aff) => aff.entityId === ministryId && aff.roleName === 'Campus Minister'
            )?.title || '',
        })) || [];
      return staffList.sort((a, b) => a.sortOrder - b.sortOrder);
    },
  },
  mutations: {
    SET_INITIAL_STATE: (state) => {
      Object.assign(state, getInitialState());
    },
    SET_STAFFS: (state, { ministryId, staffs }) => {
      state.staffs = { ...state.staffs, [ministryId]: staffs };
    },
    PUSH_TO_STAFF: (state, { ministryId, staffs }) => {
      const oldList = state.staffs[ministryId] || [];
      state.staffs = { ...state.staffs, [ministryId]: [...oldList, ...staffs] };
    },
    SET_PERSON_PARTIAL: (state, { ministryId, staffId, staffPartial }) => {
      const staffObj = state.staffs?.[ministryId]?.find((staff) => staff.id === staffId);
      const staffObjIdx = state.staffs?.[ministryId]?.findIndex((staff) => staff.id === staffId);
      const newStaffObj = { ...staffObj, ...staffPartial };
      Object.assign(state.staffs?.[ministryId][staffObjIdx], newStaffObj);
    },
    REMOVE_STAFF_BY_MINISTRY_ID: (state, removedMinistryId) => {
      delete state.staffs[removedMinistryId];
    },
    REMOVE_STAFF_MEMBER_FROM_MINISTRY: (state, { staffId, currentMinistryId }) => {
      const staffIndexToRemove = state.staffs[currentMinistryId].findIndex(
        (staff) => staff.id === staffId
      );
      state.staffs[currentMinistryId].splice(staffIndexToRemove, 1);
    },
  },
  actions: {
    // when dispatch without forceRefresh, if length is not 0 it skips over the whole thing.
    async fetchStaffsByMinistryId(
      { getters, commit },
      { ministryId, forceRefresh, getAllFromZero = false }
    ) {
      const staffsByMinistry = getters.getStaffsByMinistryId(ministryId);
      if (staffsByMinistry.length === 0 || forceRefresh || getAllFromZero) {
        const start = getAllFromZero ? 0 : staffsByMinistry.length || 0;
        const limit = getAllFromZero ? 999 : staffsByMinistry.length ? 6 : 10;
        const apiCall = `minister?start=${start}&count=${limit}&search=ministryId:${ministryId}&sort=sortOrder ASC`;
        try {
          const {
            data: { results: staffsRes, totalResults: totalStaffCount },
          } = await http.get(apiCall);
          if (staffsByMinistry.length && !getAllFromZero) {
            await commit('PUSH_TO_STAFF', { ministryId, staffs: staffsRes });
          } else {
            await commit('SET_STAFFS', { ministryId, staffs: staffsRes });
          }
          await commit(
            'pagination/SET_PAGINATION_STAFF',
            {
              ministryId,
              totalStaffCount,
              hasMore: getAllFromZero
                ? staffsRes.length < totalStaffCount
                : staffsRes.length + staffsByMinistry.length < totalStaffCount,
            },
            { root: true }
          );
        } catch (error) {
          console.log(error);
          throw error;
        }
      }
    },
    async fetchAllStaffsInCurrentMinistry({ rootGetters, dispatch }) {
      const currentMinistryId = rootGetters['ministries/getCurrentMinistryId'];
      const hasMore = rootGetters['pagination/getStaffHasMoreByMinistry'](currentMinistryId);
      try {
        if (hasMore) {
          await dispatch('fetchStaffsByMinistryId', {
            ministryId: currentMinistryId,
            forceRefresh: true,
            getAllFromZero: true,
          });
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateStaffPartial(
      { commit, getters, rootGetters },
      { staffId, ministryId, field, fieldValue }
    ) {
      try {
        const currentUserId = rootGetters['currentUser/getCurrentUserId'];
        const currentStaff = getters.getStaffObjById({ staffId, ministryId });
        const staffPartial = updateValue(
          JSON.parse(JSON.stringify(currentStaff)),
          field,
          fieldValue
        );
        await http.patch(`person/${staffId}`, staffPartial);
        commit('SET_PERSON_PARTIAL', { ministryId, staffId, staffPartial });
        if (currentUserId === currentStaff.id) {
          commit(
            'currentUser/SET_PARTIAL_UPDATE_TO_USER',
            { userPartial: staffPartial },
            { root: true }
          );
        }
      } catch (error) {
        console.log(error);
        throw new Error(CONSTANTS.ERRORS.DATA_UPDATE);
      }
    },
    async updateStaffAffiliation(
      { commit, getters, rootGetters },
      { staffId, ministryId, field, fieldValue }
    ) {
      try {
        const currentUserId = rootGetters['currentUser/getCurrentUserId'];
        const currentStaff = getters.getStaffObjById({ staffId, ministryId });
        const affiliationObjState = currentStaff?.affiliations?.find(
          (aff) => aff.entityId === ministryId
        );
        let affiliationObj = JSON.parse(JSON.stringify(affiliationObjState));
        affiliationObj[field] = fieldValue;
        console.log(affiliationObj);
        let allAffiliationsState = currentStaff?.affiliations;
        let allAffiliations = JSON.parse(JSON.stringify(allAffiliationsState));
        allAffiliations.find((aff) => aff.entityId === ministryId)[field] = fieldValue;
        console.log(allAffiliations);
        let staffPartial = { affiliations: allAffiliations };
        commit('SET_PERSON_PARTIAL', {
          ministryId,
          staffId: currentStaff.id,
          staffPartial,
        });
        if (currentUserId === currentStaff.id) {
          commit(
            'currentUser/SET_PARTIAL_UPDATE_TO_USER',
            { userPartial: staffPartial },
            { root: true }
          );
        }
        await http.put(`affiliation`, affiliationObj);
      } catch (error) {
        console.log(error);
        throw new Error(CONSTANTS.ERRORS.DATA_UPDATE);
      }
    },
    async updateStaffSortOrder(
      { commit, getters, rootGetters, dispatch },
      { staffId, ministryId, affiliationId, sortArr, index }
    ) {
      try {
        let staffListState = getters.getAllStaffById({ ministryId });
        let staffList = JSON.parse(JSON.stringify(staffListState));
        const currentUserId = rootGetters['currentUser/getCurrentUserId'];
        if (staffList.length > 0) {
          staffList.forEach((currentStaff) => {
            let allAffiliations = currentStaff.affiliations;
            if (allAffiliations) {
              let currentAff = sortArr.forEach((sortId, index) => {
                let currentAff = allAffiliations.find((aff) => aff.id === sortId);
                if (currentAff) {
                  let newSortOrder = index + 1;
                  currentAff.sortOrder = newSortOrder;
                }
              });
              let staffPartial = { affiliations: allAffiliations };
              commit('SET_PERSON_PARTIAL', {
                ministryId,
                staffId: currentStaff.id,
                staffPartial,
              });
              if (currentUserId === currentStaff.id) {
                commit(
                  'currentUser/SET_PARTIAL_UPDATE_TO_USER',
                  { userPartial: staffPartial },
                  { root: true }
                );
              }
            }
          });
          await dispatch('staffReorder', { ministryId, affId: affiliationId, index });
        }
      } catch (error) {
        console.log(error);
        throw new Error(CONSTANTS.ERRORS.DATA_UPDATE);
      }
    },
    async verifyAddingStaffEmail({ rootGetters }, emailAddress) {
      try {
        const { data: verifiedEmail } = await http.get(`person/email/${emailAddress}`);
        if (!!verifiedEmail) {
          for (const affiliation of verifiedEmail.affiliations) {
            if (affiliation.entityId === rootGetters['ministries/getCurrentMinistryId']) {
              return CONSTANTS.MODULE.STAFF_MEMBER_EMAIL_EXISTS;
            }
          }
          return verifiedEmail;
        }
        return CONSTANTS.MODULE.STAFF_MEMBER_NOT_FOUND;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async addStaffHttpRequest({}, payload) {
      try {
        const { url, method, data } = payload;
        const res = await http.request({ url, method, data });
        return res.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getPersonDetails({}, staffId) {
      try {
        const res = await http.get(`person/${staffId}`);
        return res.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async staffReorder({ commit, rootGetters }, { ministryId, affId, index = null }) {
      try {
        const role = await http.get('role?search=name:"Campus Minister"');
        const roleId = role?.data?.results?.[0]?.id;
        const allAffIds = await http.get(`affiliation/entity/${ministryId}/role/${roleId}`);
        let allAffIdsArr = allAffIds?.data;
        allAffIdsArr = allAffIdsArr.filter((aff) => aff !== affId);
        if (index !== null) {
          console.log('index: ' + index);
          console.log(affId);
          allAffIdsArr.splice(index, 0, affId);
        } else {
          console.log('no index, push to end of array');
          allAffIdsArr.push(affId);
        }
        await http.put(`affiliation/reorder`, allAffIdsArr);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteStaffAffiliation({ commit, rootGetters }, { affiliationId, staffId }) {
      try {
        const currentMinistryId = rootGetters['ministries/getCurrentMinistryId'];
        commit('REMOVE_STAFF_MEMBER_FROM_MINISTRY', { staffId, currentMinistryId });
        commit('pagination/SET_TOTAL_STAFF_COUNT_AFTER_REMOVING', currentMinistryId, {
          root: true,
        });
        await http.delete(`affiliation/${affiliationId}`);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};
