<template>
  <loading-spinner v-if="isLoading" />
  <div @click="hideDropdowns" v-show="!isLoading" :class="{ is_touch_device: isTouchDevice }">
    <div class="container" :class="{ notLoggedIn: !isLoggedIn, 'from-app': isFromApp }">
      <toast-message />
      <header>
        <div class="logo" v-if="!isLoggedIn">
          <a href="/"><logo-img /></a>
        </div>
        <header-comp v-if="isLoggedIn" />
      </header>
      <div class="page-title" v-if="!isLoggedIn && !isFromApp"><h1>Ministry Dashboard</h1></div>
      <div class="sidebar" v-if="isLoggedIn">
        <div class="sidebar-inner">
          <div class="logo" v-if="isLoggedIn">
            <a href="/"><logo-img /></a>
          </div>
          <side-nav />
        </div>
      </div>
      <main>
        <router-view />
      </main>
      <footer>
        <copyright-privacy />
      </footer>
    </div>
  </div>
  <div class="mobile-cta" v-if="!hideMobileCta && !isFromApp">
    <div class="mobile-cta-inner">
      <div class="msg">
        Hello! We noticed you are trying to access our desktop app on your mobile device. We
        recommend downloading our mobile app for the best user experience.
      </div>
      <div class="images">
        <a
          href="https://play.google.com/store/apps/details?id=com.newman.newmanconnect"
          target="_blank"
        >
          <img class="img" src="@/assets/img/mobile-app-android.png" alt="" />
        </a>
        <a href="https://apps.apple.com/us/app/newman-connect/id1515301701" target="_blank">
          <img class="img" src="@/assets/img/mobile-app-ios.png" alt="" />
        </a>
      </div>
      <div class="close">
        <a @click="useDesktopApp">Click here to continue using the desktop app.</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/reactivity';
import { useStore } from 'vuex';
import LoadingSpinner from '@/components/static/LoadingSpinner.vue';
import LogoImg from '@/components/svg/LogoImg.vue';
import SideNav from '@/components/SideNav.vue';
import HeaderComp from '@/components/Header.vue';
import CopyrightPrivacy from '@/components/static/CopyrightPrivacy.vue';
import ToastMessage from '@/components/ui/ToastMessage.vue';

export default {
  components: {
    LoadingSpinner,
    LogoImg,
    SideNav,
    HeaderComp,
    CopyrightPrivacy,
    ToastMessage,
  },
  setup() {
    const store = useStore();

    const isLoading = computed(() => store.getters.getIsDataLoading);
    const isFromApp = computed(() => store.getters.getIsFromApp);

    const loadDataIfTokenExists = async () => {
      try {
        store.dispatch('auth/setAuthToken');
        await store.dispatch('loadBasicData');
        store.commit('SET_IS_DATA_LOADING', false);
      } catch (error) {
        console.log(error);
      }
    };

    if (localStorage.getItem('jwtToken')) {
      loadDataIfTokenExists();
    }
    const isLoggedIn = computed(() => store.getters['auth/isAuthenticated']);

    const visiblePopupId = computed(() => {
      return store.getters['popups/getVisiblePopupId'];
    });

    const hideDropdowns = (event) => {
      // this prevents accidental mouseup on popup
      if (event?.target?.className !== 'inline-edit-outer-wrapper') {
        if (visiblePopupId.value?.length) {
          store.dispatch('popups/popupHide', visiblePopupId.value);
        }
      }
    };

    const isTouchDevice = computed(() => {
      return 'ontouchstart' in window || navigator.maxTouchPoints;
    });

    const hideMobileCta = ref(false);

    const useDesktopApp = () => {
      hideMobileCta.value = true;
    };

    return {
      isLoading,
      isFromApp,
      isLoggedIn,
      hideDropdowns,
      visiblePopupId,
      isTouchDevice,
      hideMobileCta,
      useDesktopApp,
    };
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/global.scss';
</style>

<style lang="scss" scoped>
.container {
  &:not(.from-app) {
    min-width: 800px;
  }

  &:not(.notLoggedIn) {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 130px 1fr auto;
    grid-template-areas:
      'sidebar header'
      'sidebar main'
      'sidebar footer';
    height: 100vh;
    background-color: #ffffff;

    .logo {
      height: 130px;
      background-color: #341d65;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sidebar {
      grid-area: sidebar;
      background: rgb(33, 13, 78);
      background: linear-gradient(
        0deg,
        rgba(33, 13, 78, 1) 0%,
        rgba(68, 43, 121, 1) 35%,
        rgba(70, 45, 123, 1) 100%
      );

      .sidebar-inner {
        position: fixed;
        top: 0;
        left: 0;
        width: 250px;
      }
    }

    header {
      grid-area: header;
      border-bottom: 1px solid #e3e3e3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      gap: 20px;
    }

    main {
      grid-area: main;
      background-color: #fafafa;
      padding: 30px;
      position: relative;
    }

    footer {
      grid-area: footer;
      border-top: 1px solid #e3e3e3;

      .copyright-privacy {
        padding: 20px 0;
      }
    }
  }

  &.notLoggedIn {
    .toast-messages-wrapper {
      transform: translate(calc(-50%), 0);
    }
    header {
      height: 115px;
      background: transparent linear-gradient(180deg, #462d7b 0%, #210d4e 100%) 0% 0% no-repeat
        padding-box;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        text-align: center;
      }
    }

    .page-title {
      text-align: center;
      padding: 60px 0;

      h1 {
        color: $purple-dark;
        font-family: $font-heading;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .content {
      background-image: url('@/assets/img/background.jpeg');
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      min-height: 650px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 50px;
      padding-bottom: 50px;

      :deep(.nli-card) {
        width: 100%;
        max-width: 600px;
        text-align: center;
        background-color: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border: 1px solid #c4c5c5;
        border-radius: 10px;
        padding: 50px;
        min-height: 200px;
      }
    }

    .copyright-privacy {
      padding-top: 20px;
    }
  }

  &.from-app {
    header {
      height: 90px;
    }

    .content {
      min-height: 0;
      padding-right: 5px;
      padding-left: 5px;

      :deep(.nli-card) {
        padding: 30px 10px;
      }
    }
  }
}

.mobile-cta {
  display: none;
  position: fixed;
  z-index: 10;
  background-color: $purple-light;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  align-items: center;
  justify-content: center;

  .mobile-cta-inner {
    padding-right: 20px;
    padding-left: 20px;

    .msg {
      font-size: 20px;
      text-align: center;
      max-width: 500px;
      line-height: 1.4;
      padding-bottom: 40px;
    }

    .images {
      display: flex;
      gap: 20px;
    }

    .close {
      text-align: center;
      padding-top: 40px;
      font-size: 16px;

      a {
        color: purple-dark;
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .container {
    &:not(.notLoggedIn) {
      grid-template-columns: 100px 1fr;

      .logo {
        a {
          width: 54px;
          overflow: hidden;
        }
      }

      .sidebar {
        .sidebar-inner {
          width: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .mobile-cta {
    display: flex;
  }
}

.is_touch_device {
  &:deep(.container) {
    .college-list .college-item a .edit-icons {
      .sort-handle i,
      .remove-icon i {
        display: inline-block;
      }
    }

    .staff-list .staff-item a .remove-icon i {
      display: inline-block;
    }

    .news-container .news-section-containers .news-grouping .rows .row a .remove-icon i {
      display: inline-block;
    }
  }
}
</style>
