<template>
  <popup
    tabindex="0"
    @keydown.esc="handleToggleAddMinistryPopup"
    maxWidth="800px"
    :showPopup="showAddPopup"
    v-if="showAddPopup"
    @close="handleToggleAddMinistryPopup"
  >
    <div v-if="fromHeader" class="popup-close-xmark">
      <i @click.stop="handleToggleAddMinistryPopup" class="fa-solid fa-xmark"></i>
    </div>
    <div class="popup">
      <h2>{{ header }}</h2>
      <div v-if="!showCreateMinistryStep2" class="step1-container">
        <p>Search for your ministry by name or college</p>
        <auto-complete
          ref="inputElMinistry"
          type="text"
          name="ministry"
          placeholder="Type your ministry name or college"
          :autocompleteSettings="autocompleteSettings"
          @handleItemClick="handleItemClick"
          @autocomplete="autocomplete"
          v-model="autocompleteSettings.ministry.searchQuery"
          :validation="validation"
          entity="ministry"
          :showSearchIcon="true"
        />
      </div>
      <div v-else class="step2-container">
        <p>Step 2: Search for your diocese</p>
        <auto-complete
          ref="inputElDiocese"
          type="text"
          name="diocese"
          placeholder="Type your diocese name, city or state"
          :autocompleteSettings="autocompleteSettings"
          @handleItemClick="handleItemClick"
          @autocomplete="autocomplete"
          v-model="autocompleteSettings.diocese.searchQuery"
          entity="diocese"
          :showSearchIcon="true"
        />
      </div>
      <div class="buttons">
        <div class="back-btn" v-if="showCreateMinistryStep2">
          <btn
            class="secondary"
            label="Back"
            icon="fa-solid fa-caret-left"
            @click="goBackToStep1"
          />
        </div>
        <btn
          v-if="fromHeader"
          class="secondary"
          label="Cancel"
          @click="handleToggleAddMinistryPopup"
        />
        <btn
          class="primary"
          :icon="saveBtnIcon"
          :disabled="saveBtnIsLoading || saveBtnDisabled"
          :loading="saveBtnIsLoading"
          :label="saveBtnLabel"
          @click="handleAddOrCreateMinistry"
        />
      </div>
    </div>
  </popup>
</template>

<script>
import { ref, computed, reactive } from '@vue/reactivity';
import Popup from '@/components/ui/Popup.vue';
import Btn from '@/components/ui/Btn.vue';
import AutoComplete from '@/components/ui/AutoComplete.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { sendToastMessage } from '@/utils/helpers';
import CONSTANTS from '@/utils/constants';

export default {
  props: {
    fromHeader: {
      required: false,
      type: Boolean,
    },
    showAddPopup: {
      required: false,
      type: Boolean,
    },
    currentUserFirstName: {
      required: false,
      type: String,
    },
  },
  emit: ['handleToggleAddMinistryPopup'],
  components: {
    Popup,
    Btn,
    AutoComplete,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    const header = computed(() => {
      return props.currentUserFirstName
        ? `Welcome, ${props.currentUserFirstName}`
        : 'Add New Ministry';
    });

    const saveBtnDisabled = computed(() => {
      if (showCreateMinistryStep2.value) {
        return !autocompleteSettings.value.diocese.itemSelected;
      } else {
        return autocompleteSettings.value.ministry.searchQuery.length === 0;
      }
    });

    const saveBtnIsLoading = ref(false);

    const saveBtnLabel = computed(() => {
      if (autocompleteSettings.value.ministry.itemSelected) {
        return 'Join Ministry';
      } else {
        if (showCreateMinistryStep2.value) {
          return 'Create New Ministry';
        } else {
          return 'Continue';
        }
      }
    });

    const saveBtnIcon = computed(() => {
      if (autocompleteSettings.value.ministry.itemSelected || showCreateMinistryStep2.value) {
        return 'fa-solid fa-circle-plus';
      } else {
        return '';
      }
    });

    const addMinistryValidation = computed(() => {
      if (
        autocompleteSettings.value.ministry.searchQuery.length > 0 &&
        autocompleteSettings.value.ministry.results.length === 0 &&
        !autocompleteSettings.value.ministry.itemSelected
      ) {
        // failing
        return true;
      } else {
        // passing
        return false;
      }
    });

    const validation = computed(() => {
      return {
        message: "We couldn't find that ministry in our system. A new ministry will be created.",
        type: 'info',
        failing: addMinistryValidation.value,
      };
    });

    // START: AutoComplete

    const autocompleteSettings = ref({
      searchFrequency: 300,
      searchTimer: null,
      ministry: {
        results: [],
        searchQuery: '',
        itemSelected: null,
      },
      diocese: {
        results: [],
        searchQuery: '',
        itemSelected: null,
      },
    });

    const generateResults = (items, entity) => {
      // set results array with data for autocomplete to consume

      autocompleteSettings.value[entity].results.length = 0;

      if (entity === 'ministry') {
        items.forEach((ministry) => {
          let collegeNames = [];
          ministry.colleges.forEach((college) => {
            if (collegeNames.length <= 5 && college.name && college.name.length > 0) {
              collegeNames.push(college.name);
            }
          });
          let resultObj = {
            id: ministry.id,
            name: ministry.name || 'No Ministry Name',
            line1: ministry.name || 'No Ministry Name',
            line2: collegeNames,
          };
          autocompleteSettings.value[entity].results.push(resultObj);
        });
      } else if (entity === 'diocese') {
        items.forEach((diocese) => {
          let resultObj = {
            id: diocese.id,
            name: diocese.name || 'No Diocese Name',
            line1: diocese.name || 'No Diocese Name',
          };
          autocompleteSettings.value[entity].results.push(resultObj);
        });
      }
    };

    const autocomplete = async (value, typing, entity) => {
      if (typing === true) {
        autocompleteSettings.value[entity].itemSelected = null;
      }
      autocompleteSettings.value[entity].searchQuery = value;
      autocompleteSettings.value.searchTimer &&
        clearInterval(autocompleteSettings.value.searchTimer);
      const timer = setTimeout(async () => {
        try {
          if (autocompleteSettings.value[entity].searchQuery.length) {
            let action;
            if (entity === 'ministry') {
              action = 'ministries/ministriesAutocomplete';
            } else if (entity === 'diocese') {
              action = 'diocese/dioceseAutocomplete';
            }
            const ministriesRes = await store.dispatch(
              action,
              autocompleteSettings.value[entity].searchQuery
            );
            generateResults(ministriesRes.results, entity);
          } else {
            autocompleteSettings.value[entity].results = [];
          }
        } catch (error) {
          console.log(error);
          sendToastMessage('error', CONSTANTS.ERRORS.GENERIC_LOADING);
        }
      }, autocompleteSettings.value.searchFrequency);
      autocompleteSettings.value.searchTimer = timer;
    };

    const handleItemClick = (item, entity) => {
      autocompleteSettings.value[entity].searchQuery = item.name;
      autocompleteSettings.value[entity].itemSelected = item;
      autocompleteSettings.value[entity].results = [];
    };

    // END: AutoComplete

    const handleAddOrCreateMinistry = async () => {
      let ministryId;
      try {
        saveBtnIsLoading.value = true;
        if (!autocompleteSettings.value.ministry.itemSelected) {
          if (!autocompleteSettings.value.diocese.itemSelected) {
            showCreateMinistryStep2.value = true;
            saveBtnIsLoading.value = false;
            return;
          }
          const createMinistryResponse = await store.dispatch('ministries/createMinistry', {
            name: autocompleteSettings.value.ministry.searchQuery,
            dioceseId: autocompleteSettings.value.diocese.itemSelected.id,
          });
          ministryId = createMinistryResponse.data.id;
        } else {
          ministryId = autocompleteSettings.value.ministry.itemSelected.id;
        }
        let dataToSend = {
          personId: store.getters['currentUser/getCurrentUserId'],
          entityId: ministryId,
          roleName: 'CAMPUS MINISTER',
        };
        await store.dispatch('currentUser/addMinistryToCurrentUser', dataToSend);
        handleToggleAddMinistryPopup();
        store.commit('SET_IS_DATA_LOADING', true);
        await store.dispatch('ministries/fetchMinistry', ministryId);
        await store.dispatch('ministries/setCurrentMinistryId', ministryId);
        await store.dispatch('colleges/fetchCollegesByMinistryId', { ministryId });
        await store.dispatch('staffs/fetchStaffsByMinistryId', { ministryId });
        await router.push({ name: 'MinistryOverview', params: { ministryId } });
        store.commit('SET_IS_DATA_LOADING', false);
        sendToastMessage('success', CONSTANTS.SUCCESSES.MINISTRY_ADD);
        saveBtnIsLoading.value = false;
      } catch (error) {
        // console.error(error);
        sendToastMessage('error', CONSTANTS.ERRORS.GENERIC_ERROR);
        store.commit('SET_IS_DATA_LOADING', false);
        saveBtnIsLoading.value = false;
      }
    };

    const resetAutocompleteSettings = () => {
      autocompleteSettings.value.searchTimer = null;
      autocompleteSettings.value.ministry.searchQuery = '';
      autocompleteSettings.value.ministry.itemSelected = null;
      autocompleteSettings.value.ministry.results.length = 0;
      autocompleteSettings.value.diocese.searchQuery = '';
      autocompleteSettings.value.diocese.itemSelected = null;
      autocompleteSettings.value.diocese.results.length = 0;
    };

    const handleToggleAddMinistryPopup = () => {
      if (props.fromHeader) {
        emit('handleToggleAddMinistryPopup');
        resetAutocompleteSettings();
        showCreateMinistryStep2.value = false;
      }
    };

    const showCreateMinistryStep2 = ref(false);

    const goBackToStep1 = () => {
      resetAutocompleteSettings();
      showCreateMinistryStep2.value = false;
    };

    return {
      header,
      autocompleteSettings,
      autocomplete,
      handleItemClick,
      handleAddOrCreateMinistry,
      handleToggleAddMinistryPopup,
      addMinistryValidation,
      validation,
      saveBtnDisabled,
      saveBtnIsLoading,
      saveBtnLabel,
      saveBtnIcon,
      showCreateMinistryStep2,
      goBackToStep1,
    };
  },
};
</script>

<style lang="scss" scoped>
.popup-container {
  align-items: flex-start;

  &:deep(.popup-content) {
    margin-top: 130px;
  }
}
.popup-close-xmark {
  font-size: 16px;
  line-height: 1;
  color: $purple-dark;
  position: absolute;
  top: 17px;
  right: 20px;

  i {
    cursor: pointer;
  }
}
.popup {
  text-align: center;
  padding: 20px;

  h2 {
    color: $purple-dark;
    padding-bottom: 30px;
    font-size: 22px;
  }

  p {
    padding-bottom: 30px;
  }

  .cta {
    font-size: 16px;
    padding-bottom: 40px;
  }

  .buttons {
    display: flex;
    justify-content: right;

    button.btn {
      min-height: 40px;
      min-width: 160px;

      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }
    }

    .back-btn {
      margin-right: auto;

      button.btn {
        // min-width: 0;
        margin-left: 0;
      }
    }
  }
}
h2.heading {
  font-size: 24px;
  color: $purple-dark;
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: left;

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    span {
      &:last-child {
        color: $blue;
        font-size: 18px;
        padding-left: 10px;
      }
    }
  }
}
.step2-container {
  padding-bottom: 30px;
}
.add-cta {
  color: $blue;
  font-size: 28px;
  position: absolute;
  top: 10px;
  right: 10px;

  i {
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: #0187bc;
    }
  }
}
</style>
