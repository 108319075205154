<template>
  <div class="copyright-privacy">
    &copy; {{ year }} Newman Ministry<span>|</span>
    <a href="https://newmanministry.com/privacypolicy/" target="_blank">Privacy</a>
    <span>|</span>
    <a href="https://newmanministry.com/terms/" target="_blank">Terms</a>
  </div>
</template>

<script>
export default {
  setup() {
    const date = new Date();
    const year = date.getFullYear();

    return { year };
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';

.copyright-privacy {
  color: $gray-medium;
  font-size: 14px;
  font-family: $font-paragraph;
  text-align: center;

  a {
    color: $gray-medium;

    &:hover {
      color: $purple-dark;
    }
  }

  span {
    padding: 0 10px;
  }
}
</style>
