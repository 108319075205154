import http from '@/api';
import dayjs from 'dayjs';
import {
  get,
  getImageUrl,
  getFullName,
  getPrimaryCity,
  getPrimaryState,
  getPrimaryAddress,
  getPrimaryPhone,
  getPrimaryEmail,
  getPrimaryPostalCode,
  getCollege,
  getHighSchool,
  getParish,
  getEntityName,
  ifDayJsValid,
  getStudentStatsQuery,
  updateValue,
  generateApiSearchQuery,
} from '@/utils/helpers';
import CONSTANTS from '@/utils/constants';

const getInitialState = () => ({
  studentList: {
    studentCount: 0,
    imagesNewStudents: [],
    numberNewStudents: 0,
    collegeSelectionData: [],
    yearSelectionData: [],
    searchParams: {
      keyword: '',
      collegeId: 'all',
      year: new Date().getFullYear(),
    },
    searchResult: {
      totalResults: 0,
      students: [],
    },
    ministryId: null,
    pagination: {
      hasMore: false,
      start: 0,
    },
  },
  studentProfile: {},
});

export default {
  namespaced: true,
  state: getInitialState,
  getters: {
    getStudentCount: (state) => state.studentList.studentCount,
    getHasMore: (state) => state.studentList.pagination.hasMore,
    getStart: (state) => state.studentList.pagination.start,
    getImagesNewStudents: (state) =>
      state.studentList.imagesNewStudents.map((imageGuid) =>
        imageGuid ? getImageUrl(imageGuid) : ''
      ),
    getNumberNewStudents: (state) => state.studentList.numberNewStudents,
    getSearchParams: (state) => state.studentList.searchParams,
    getCollegeSelectionData: (state) => state.studentList.collegeSelectionData,
    getYearSelectionData: (state) => state.studentList.yearSelectionData,
    getSearchResult: (state) => state.studentList.searchResult,
    getStudentProfile: (state) => state.studentProfile,
    getStudentProfileName: (state) => getFullName(state.studentProfile) || '',
    getStudentProfileHasAccount: (state) => state.studentProfile?.accountHolder,
    getStudentProfileImageGuid: (state) => state.studentProfile?.imageGuid || '',
    getStudentProfileImageUrl: (state) => getImageUrl(state.studentProfile?.imageGuid),
    getStudentProfileAddress: (state) => getPrimaryAddress(state.studentProfile),
    getStudentProfileEmail: (state) => getPrimaryEmail(state.studentProfile) || '',
    getStudentProfilePhone: (state) => getPrimaryPhone(state.studentProfile) || '',
    getStudentProfileSocialMedia: (state) => state.studentProfile?.socialMediaAccounts || [],
    getStudentProfileAffiliations: (state) => state.studentProfile?.affiliations || [],
    getStudentProfileNotes: (state) => state.studentProfile?.notes || '',
  },
  mutations: {
    SET_MINISTRY_ID(state, ministryId) {
      state.studentList.ministryId = ministryId;
    },
    SET_INITIAL_STATE: (state) => {
      Object.assign(state, getInitialState());
    },
    SET_STAT_DATA: (state, data) => {
      state.studentList.studentCount = data?.totalStudents ?? 0;
      state.studentList.numberNewStudents = data?.newStudentDetails?.newStudents ?? 0;
      state.studentList.collegeSelectionData = data?.collegeCounts.sort((a, b) => {
        let fa = a.collegeName.toLowerCase(),
          fb = b.collegeName.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      state.studentList.yearSelectionData = data?.classCounts.sort((a, b) => {
        return b.gradYear - a.gradYear;
      });

      let imageUrlsArr = data?.newStudentDetails?.newStudentImageGuids ?? [];
      let newImageUrlsArr = [...imageUrlsArr];
      let total = 0;
      if (state.studentList.numberNewStudents > 2) {
        total = 3;
      } else if (state.studentList.numberNewStudents > 1) {
        total = 2;
      } else if (state.studentList.numberNewStudents > 0) {
        total = 1;
      }
      for (let i = 0; i < total - imageUrlsArr.length; i++) {
        newImageUrlsArr.push('');
      }

      state.studentList.imagesNewStudents = newImageUrlsArr;
      state.studentList.searchParams = {
        keyword: state.studentList.searchParams.keyword,
        collegeId: state.studentList.searchParams.collegeId || 'all',
        year: state.studentList.searchParams.year || 'all',
      };
    },
    SET_SEARCH_PARAMS: (state, params) => {
      Object.assign(state.studentList.searchParams, params);
    },
    SET_SEARCH_RESULT: (state, { totalResults, students }) => {
      state.studentList.searchResult = {
        totalResults,
        students,
      };
    },
    APPEND_STUDENTS: (state, students) => {
      state.studentList.searchResult.students =
        state.studentList.searchResult.students.concat(students);
    },
    SET_HAS_MORE: (state, hasMore) => {
      state.studentList.pagination.hasMore = hasMore;
    },
    SET_START: (state, start) => {
      state.studentList.pagination.start = start;
    },
    SET_STUDENT_PROFILE: (state, studentObj) => {
      state.studentProfile = studentObj;
    },
    SET_STUDENT_PROFILE_PARTIAL: (state, studentPartial) => {
      const newStudentObj = { ...state.studentProfile, ...studentPartial };
      Object.assign(state.studentProfile, newStudentObj);
    },
    SET_STUDENT_LIST_PARTIAL: (state, { studentPartial, studentId }) => {
      const studentMatch = state.studentList.searchResult.students.find(
        (student) => student.id === studentId
      );
      // only update user if they exist...
      if (studentMatch) {
        const studentMatchIndex = state.studentList.searchResult.students.findIndex(
          (student) => student.id === studentId
        );
        const currStudent = state.studentList.searchResult.students[studentMatchIndex];
        // only update fields used in student list...
        const spKeyArr = Object.getOwnPropertyNames(studentPartial);
        // name
        if (spKeyArr.includes('firstName') && spKeyArr.includes('lastName')) {
          currStudent.name = `${studentPartial.firstName} ${studentPartial.lastName}`;
        }
        // email
        if (spKeyArr.includes('emails')) {
          currStudent.email = studentPartial.emails[0].email;
        }
        // phone
        if (spKeyArr.includes('phoneNumbers')) {
          currStudent.phone = studentPartial.phoneNumbers[0].number;
        }
        // image
        if (spKeyArr.includes('imageGuid')) {
          currStudent.imageUrl = getImageUrl(studentPartial.imageGuid);
        }
        // college
        if (spKeyArr.includes('college')) {
          currStudent.college = studentPartial.college;
        }
        // high school or parish
        if (spKeyArr.includes('highSchool')) {
          currStudent.hs_parish = studentPartial.highSchool;
        }
      }
    },
  },
  actions: {
    async fetchStatData({ commit, getters, state }, ministryId) {
      const { keyword, year, collegeId } = getters.getSearchParams;
      if (ministryId) commit('SET_MINISTRY_ID', ministryId);
      const url = `/student/ministry/${state.studentList.ministryId}/stats`;
      let queryString = `${keyword ? `(name:"${keyword}" OR email:"${keyword}")` : ''}`;
      if (year !== 'all') {
        queryString = `${queryString} AND highSchoolGraduationYear:${year}`;
      }
      if (collegeId !== 'all') {
        queryString = `${queryString} AND collegeId:${collegeId}`;
      }
      const { data } = await http.get(queryString ? `${url}?search=${queryString}` : url);
      // push current year if not in returned data
      let currentYearObj = data?.classCounts.find(
        (year) => year.gradYear === new Date().getFullYear()
      );
      if (!currentYearObj) {
        data?.classCounts.push({
          gradYear: new Date().getFullYear(),
          count: 0,
        });
      }
      commit('SET_STAT_DATA', data);
    },
    async setSearchParams({ commit, dispatch }, params) {
      commit('SET_SEARCH_PARAMS', params);
      await dispatch('fetchStatData');
      commit('SET_HAS_MORE', false); // reset
      commit('SET_START', 0); // reset
      await dispatch('fetchStudents');
    },
    async fetchStudents({ getters, commit, state }, lazyload = false) {
      const start = getters.getStart;
      let params = {
        start,
      };
      const paramsString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
      const queryString = getStudentStatsQuery({
        ...getters.getSearchParams,
        ministryId: state.studentList.ministryId,
      });
      const url = `/student?search=${queryString}&${paramsString}&sort=highSchoolGraduationYear DESC`;
      const { data } = await http.get(url);
      const totalResults = data.totalResults;
      const students = data.results.map((student) => ({
        id: get(student, 'id') || '-',
        imageUrl: getImageUrl(student.imageGuid),
        name: getFullName(student) || '-',
        email: getPrimaryEmail(student) || '-',
        social: student.socialMediaAccounts || '-',
        phone: getPrimaryPhone(student) || '-',
        hs_parish:
          getEntityName(getHighSchool(student)) || getEntityName(getParish(student)) || '-',
        college: getEntityName(getCollege(student)) || '-',
        highSchoolGraduationYear: student.highSchoolGraduationYear
          ? student.highSchoolGraduationYear
          : '-',
      }));

      if (lazyload) {
        commit('APPEND_STUDENTS', students);
      } else {
        commit('SET_SEARCH_RESULT', { totalResults, students });
      }
      // Set hasMore and Start
      const hasMore = totalResults > getters.getSearchResult.students.length;
      commit('SET_HAS_MORE', hasMore);
      commit('SET_START', start + 20);
    },
    async lazyloadStudents({ dispatch, getters }) {
      await dispatch('fetchStudents', true);
    },
    async fetchStudentProfile({ commit, dispatch }, studentId) {
      const response = await http.get(`student/${studentId}`);
      commit('SET_STUDENT_PROFILE', response.data);
      return response.data;
    },
    async updateStudentProfilePartial({ commit, getters, state }, { field, fieldValue }) {
      try {
        const studentPartial = updateValue(
          JSON.parse(JSON.stringify(state.studentProfile)),
          field,
          fieldValue
        );
        // console.log(studentPartial);
        await http.patch(`person/${state.studentProfile.id}`, studentPartial);
        commit('SET_STUDENT_PROFILE_PARTIAL', studentPartial);
        commit('SET_STUDENT_LIST_PARTIAL', { studentPartial, studentId: state.studentProfile.id });
      } catch (error) {
        console.log(error);
        throw new Error(CONSTANTS.ERRORS.DATA_UPDATE);
      }
    },
    async autocompleteSearch({}, { searchQuery, entity }) {
      try {
        let sharedQuery = generateApiSearchQuery(searchQuery, ['name', 'city', 'state']);
        let apiEndpoint;
        if (entity === 'highSchool') {
          apiEndpoint = `entity?search=(entityType:HIGH_SCHOOL OR entityType:PARISH) AND (${sharedQuery})`;
        } else if (entity === 'college') {
          apiEndpoint = `college?search=${sharedQuery}`;
        }
        const result = await http.get(apiEndpoint);
        return result.data;
      } catch (error) {
        throw error;
      }
    },
  },
};
