<template>
  <button :class="btnClass" @click.stop="onClick" :disabled="disabled">
    <i v-if="icon" :class="icon"></i>
    <span v-if="label" class="label">{{ label }}</span>
    <span v-if="count" class="count">({{ count }})</span>
    <span v-if="notificationCount" class="notification-count">{{ notificationCount }}</span>
    <loading-ring v-if="loading" :color="disabled ? '#ffffff' : '#000000'" />
  </button>
</template>

<script>
import { computed } from '@vue/reactivity';
import LoadingRing from './LoadingRing.vue';

export default {
  name: 'BtnEl',
  components: { LoadingRing },
  emits: ['click'],
  props: {
    class: {
      required: true,
      type: String,
    },
    loading: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    label: {
      required: false,
      type: String,
    },
    count: {
      required: false,
      type: [String, Number],
    },
    notificationCount: {
      required: false,
      type: [String, Number],
    },
    icon: {
      required: false,
      type: String,
    },
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit('click');
    };

    const btnClass = computed(() => ({
      btn: true,
      primary: props.class === 'primary',
      secondary: props.class === 'secondary',
      'red-primary': props.class === 'red-primary',
      'red-secondary': props.class === 'red-secondary',
      delete: props.class === 'delete',
      'green-secondary': props.class === 'green-secondary',
      'gray-red-secondary': props.class === 'gray-red-secondary',
    }));

    return {
      onClick,
      btnClass,
    };
  },
};
</script>

<style lang="scss" scoped>
button.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-heading;
  font-weight: 600;
  line-height: 1;
  padding: 0 15px;
  border: 1px solid $blue;
  cursor: pointer;
  border-radius: 9px;
  transition: background-color 0.3s;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  min-height: 50px;

  span {
    font-size: 16px;

    &.count {
      padding-left: 10px;
    }

    &.notification-count {
      margin-left: 10px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: $red;
      color: #ffffff;
      font-size: 13px;
      line-height: 22px;
    }
  }

  i {
    font-size: 18px;
    padding-right: 10px;
  }

  &.primary {
    color: #ffffff;
    background-color: $blue;

    &:hover {
      background-color: #0187bc;
    }
  }

  &.secondary {
    color: $blue;
    background-color: #ffffff;
    border-color: $blue;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &.red-primary {
    color: #ffffff;
    background-color: $red;
    border-color: $red;

    &:hover {
      background-color: #9d2d2e;
    }
  }

  &.red-secondary {
    color: $red;
    background-color: #ffffff;
    border-color: $red;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &.green-secondary {
    color: #127700;
    background-color: #ffffff;
    border-color: #127700;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &.gray-red-secondary {
    color: $icon-gray;
    background-color: #ffffff;
    border-color: $icon-gray;

    &:hover {
      background-color: #f5f5f5;
      color: $red;
      border-color: $red;
    }
  }

  &.delete {
    color: $icon-gray;
    background-color: #ffffff;
    border-color: $icon-gray;

    &:hover {
      background-color: #f5f5f5;
      color: $red;
      border-color: $red;
    }
  }

  &[disabled] {
    background-color: #767676;
    color: #ffffff;
    border-color: #767676;
    cursor: not-allowed;

    &:hover {
      background-color: #767676;
      color: #ffffff;
      border-color: #767676;
    }
  }

  .loading-ring {
    padding: 0 15px 0 10px;
  }
}
</style>
