<template>
  <div class="popup-container" v-if="showPopup" @click="$emit('close')">
    <div
      class="popup-content"
      :style="[maxWidth ? { maxWidth: maxWidth } : '', maxHeight ? { maxHeight: maxHeight } : '']"
      @click.stop=""
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupEl',
  components: {},
  props: {
    showPopup: {
      type: Boolean,
      required: true,
    },
    maxWidth: {
      type: String,
      required: false,
    },
    maxHeight: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.popup-container {
  position: fixed;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  padding: 20px;
  background-color: #ffffff;
  width: 100%;
  position: relative;
  border-radius: 10px;

  @media screen and (max-width: 450px) {
    padding: 10px;
  }
}
</style>
