<template>Root.vue</template>

<script>
// @ is an alias to /src

export default {
  name: 'RootView',
  components: {},
};
</script>
