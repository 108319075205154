import http from '@/api';
import {
  getImageUrl,
  getFullName,
  updateValue,
  getPrimaryAddress,
  getPrimaryPhone,
  getPrimaryEmail,
  getRelativeTimeString,
  generateApiSearchMultiple,
  generateApiSearchQuery,
} from '@/utils/helpers';
import CONSTANTS from '@/utils/constants';

const getInitialState = () => ({
  people: [],
  pagination: {
    totalResults: 0,
    count: 0,
    hasMore: false,
  },
  roles: [],
});

export default {
  namespaced: true,
  state: getInitialState,
  getters: {
    getPeopleObjById:
      (state) =>
      ({ personId }) => {
        const personObj = state.people.find((person) => person.id === personId);
        return personObj || {};
      },
    getPeople: (state) => state.people,
    getTotalResult: (state) => state.pagination.totalResults || 0,
    getCount: (state) => state.pagination.count || 0,
    getHasMore: (state) => state.pagination.hasMore || false,
    getRoles: (state) => state.roles,
    getRoleIdFromRoleName: (state) => (roleName) => {
      const role = state.roles?.find((x) => x.name.toLowerCase() === roleName.toLowerCase());
      if (role) {
        return role.id;
      }
      return null;
    },
  },
  mutations: {
    SET_INITIAL_STATE: (state) => {
      Object.assign(state, getInitialState());
    },
    SET_INITIAL_PEOPLE_STATE: (state) => {
      state.people = [];
      state.pagination.totalResults = 0;
      state.pagination.count = 0;
      state.pagination.hasMore = false;
    },
    SET_PEOPLE: (state, people) => {
      state.people = people;
    },
    PUSH_TO_PEOPLE: (state, { people }) => {
      state.people = state.people.concat(people);
    },
    SET_TOTAL_RESULTS: (state, totalResults) => {
      state.pagination.totalResults = totalResults;
    },
    SET_COUNT: (state, count) => {
      state.pagination.count = count;
    },
    SET_HAS_MORE: (state, hasMore) => {
      state.pagination.hasMore = hasMore;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
  },
  actions: {
    async fetchRoles({ commit }) {
      try {
        const {
          data: { results: roles },
        } = await http.get('/role?start=0&count=999');

        commit('SET_ROLES', roles);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async fetchPeople(
      { getters, rootGetters, commit },
      { keyword, roleNames = [], lazyload = false, forceRefresh = false }
    ) {
      try {
        const currentMinistryEntityIds =
          rootGetters['ministries/getCurrentMinistryRelatedEntityIds'];
        const entityIdQuery = generateApiSearchMultiple(currentMinistryEntityIds, 'entityId');
        const roleIds = roleNames.map((role) => getters.getRoleIdFromRoleName(role));
        const roleIdQuery = roleIds?.length ? generateApiSearchMultiple(roleIds, 'roleId') : null;
        const keywordSearch = keyword?.length
          ? generateApiSearchQuery(keyword, ['firstName', 'lastName'])
          : null;

        let start;
        if (forceRefresh) {
          start = 0;
        } else {
          start = getters.getCount;
        }

        let queryString = `/person?start=${start}&search=(${entityIdQuery})`;
        if (roleIdQuery) {
          queryString += ` AND (${roleIdQuery})`;
        }
        if (keywordSearch) {
          queryString += ` AND (${keywordSearch})`;
        }

        const {
          data: { results: peopleRes, totalResults: totalPeopleCount },
        } = await http.get(queryString);

        if (lazyload) {
          commit('PUSH_TO_PEOPLE', { people: peopleRes });
          commit('SET_COUNT', start + peopleRes.length);
        } else {
          commit('SET_PEOPLE', peopleRes);
          commit('SET_COUNT', peopleRes.length);
        }

        commit('SET_TOTAL_RESULTS', totalPeopleCount);

        const hasMore = totalPeopleCount > start + peopleRes.length;
        commit('SET_HAS_MORE', hasMore);

        return peopleRes;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
};
