<template>
  <div class="input-text" :class="{ ...classObj, disabled }" @click.stop="">
    <div class="ac-input-wrapper">
      <div class="ac-input">
        <input
          ref="inputEl"
          :type="type ? type : 'text'"
          :name="name"
          :placeholder="placeholder"
          :value="modelValue"
          :disabled="disabled"
          autocomplete="off"
          @blur="$emit('blur', $event.target.value)"
          @input="$emit('autocomplete', $event.target.value, true, entity)"
          @focus="$emit('autocomplete', $event.target.value, false, entity)"
        />
        <i class="fa-solid fa-magnifying-glass" v-if="showSearchIcon"></i>
        <i class="fa-solid fa-pencil" v-if="showEditIcon"></i>
      </div>
      <div
        class="autocomplete"
        v-if="autocompleteSettings[entity].results.length"
        :style="{
          overflowY: autocompleteSettings[entity].results.length > 5 ? 'scroll' : 'auto',
        }"
      >
        <ul class="results">
          <li
            v-for="(item, idx) in autocompleteSettings[entity].results"
            :key="idx + '-' + generateUUID"
            @click.stop="$emit('handleItemClick', item, entity)"
          >
            <div class="primary-line">{{ item.line1 }}</div>
            <div class="secondary-line" v-if="item.line2">
              <div v-for="(item2, idx2) in item.line2" :key="idx2 + '-' + generateUUID">
                <span>{{ item2 }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <error-message
      :validationStatus="validationObject"
      :customErrorMessage="validation?.message"
      v-if="validation"
    />
  </div>
</template>

<script>
import { onMounted } from 'vue';
import { ref, computed, reactive } from '@vue/reactivity';
import ErrorMessage from '@/components/ui/ErrorMessage.vue';
import { generateUUID } from '@/utils/helpers';

export default {
  components: {
    ErrorMessage,
  },
  emits: ['blur', 'autocomplete', 'handleItemClick'],
  props: {
    name: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    modelValue: {
      required: true,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    type: {
      required: false,
      type: String,
    },
    validation: {
      required: false,
      type: Object,
    },
    autocompleteSettings: {
      required: true,
      type: Object,
    },
    disableInitialFocus: {
      required: false,
      type: Boolean,
    },
    entity: {
      required: true,
      type: String,
    },
    position: {
      required: false,
      type: String,
    },
    showEditIcon: {
      required: false,
      type: Boolean,
    },
    showSearchIcon: {
      required: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const inputEl = ref(null);
    const getInputRef = () => inputEl.value;

    const validationObject = computed(() => {
      if (props.validation) {
        return {
          $error: props.validation.failing,
        };
      } else {
        return {};
      }
    });

    const classObj = reactive({
      absolute: props.position && props.position === 'absolute',
      'show-edit-icon': props.showEditIcon,
      'show-search-icon': props.showSearchIcon,
    });

    onMounted(() => {
      if (!props.disableInitialFocus) {
        inputEl.value.focus();
      }
    });

    return {
      inputEl,
      getInputRef,
      generateUUID,
      validationObject,
      classObj,
    };
  },
};
</script>

<style lang="scss" scoped>
.input-text {
  padding-bottom: 15px;

  &.disabled {
    pointer-events: none;
  }

  &.absolute {
    .ac-input-wrapper {
      .autocomplete {
        position: absolute;
        top: 50px;
        left: 0;
      }
    }
  }

  &.show-edit-icon {
    .ac-input {
      input {
        padding-right: 50px;
      }

      i.fa-pencil {
        display: none;
        position: absolute;
        font-size: 18px;
        right: 15px;
        top: 16px;
        color: $blue;
      }
    }

    &:not(.disabled) {
      .ac-input {
        &:hover {
          i.fa-pencil {
            display: inline-block;
          }
        }
      }
    }
  }

  &.show-search-icon {
    .ac-input {
      input {
        padding-left: 50px;
      }

      i.fa-magnifying-glass {
        position: absolute;
        font-size: 22px;
        left: 15px;
        top: 16px;
        color: $purple-dark;
      }
    }
  }

  .ac-input {
    position: relative;
    input {
      width: 100%;
      background-color: $purple-light;
      border: 1px solid #aaaaaa;
      border-radius: 9px;
      font-family: $font-paragraph;
      font-size: 16px;
      color: #000000;
      padding: 0 15px;
      height: 50px;
    }
  }

  .error-message-container {
    padding-left: 8px;

    :deep(.error-message) {
      color: #916d2f;
    }
  }
}

.ac-input-wrapper {
  position: relative;
  text-align: left;
  .autocomplete {
    width: 100%;
    max-height: 300px;
    z-index: 1;
    background-color: #ffffff;
    border: 1px solid #aaaaaa;
    border-radius: 9px;

    ul.results {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        border-bottom: 1px solid #aaaaaa;
        padding: 20px;
        cursor: pointer;

        &:last-child {
          border-bottom-width: 0;
        }

        &:hover {
          background-color: $purple-light;
        }

        .primary-line {
          font-size: 16px;
          font-weight: 500;
          color: #462d7b;
        }

        .secondary-line {
          padding: 5px 0 0 10px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
