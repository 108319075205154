import http from '@/api';
import jwt_decode from 'jwt-decode';
import store from '..';
import CONSTANTS from '@/utils/constants';

const getInitialState = () => ({
  jwtToken: null,
  isLoggingOut: false,
});

export default {
  namespaced: true,
  state: getInitialState,
  getters: {
    jwtToken: (state) => state.jwtToken,
    isAuthenticated: (state) => {
      const token = state.jwtToken;
      return token && jwt_decode(token).exp * 1000 > Date.now();
    },
    isLoggingOut: (state) => state.isLoggingOut,
  },
  mutations: {
    SET_INITIAL_STATE: (state) => {
      Object.assign(state, getInitialState());
    },
    SET_AUTH_TOKEN: (state, value) => {
      state.jwtToken = value;
    },
    SET_IS_LOGGING_OUT: (state, value) => {
      state.isLoggingOut = value;
    },
  },
  actions: {
    async login({ dispatch }, credentials) {
      try {
        const { data } = await http.post('login', credentials);
        localStorage.setItem('jwtToken', data.token);
        dispatch('setAuthToken');
      } catch (error) {
        console.log(error);
        throw new Error(CONSTANTS.ERRORS.LOGIN);
      }
    },
    async setAuthToken({ commit }) {
      commit('SET_AUTH_TOKEN', localStorage.getItem('jwtToken'));
    },
    async logout({ commit }, callDeleteLogin = true) {
      commit('SET_IS_LOGGING_OUT', true);
      try {
        if (callDeleteLogin) {
          await http.delete('login');
        }
        commit('SET_INITIAL_STATE');
        const moduleStatesToReset = [
          'popups',
          'colleges',
          'currentUser',
          'ministries',
          'pagination',
          'staffs',
          'toast',
          'students',
          'diocese',
        ];
        moduleStatesToReset.forEach((fileName) => {
          commit(fileName + '/SET_INITIAL_STATE', null, { root: true });
        });
      } catch (e) {
        console.log(e);
      }
      if (callDeleteLogin) {
        localStorage.clear();
      } else {
        localStorage.removeItem('jwtToken');
      }
      commit('SET_AUTH_TOKEN', null);
      commit('SET_IS_LOGGING_OUT', false);
    },
  },
};
