<template>
  <popup
    tabindex="0"
    @keydown.esc="handleToggleRemoveMinistryPopup"
    maxWidth="800px"
    :showPopup="showRemovePopup"
    v-if="showRemovePopup"
    @close="handleToggleRemoveMinistryPopup"
  >
    <div class="popup-close-xmark">
      <i @click.stop="handleToggleRemoveMinistryPopup" class="fa-solid fa-xmark"></i>
    </div>
    <div class="popup">
      <h2>Remove Ministry Connection</h2>
      <p v-html="removeMessage"></p>
      <div class="buttons">
        <btn class="secondary" label="Cancel" @click="handleToggleRemoveMinistryPopup" />
        <btn
          class="red-primary"
          label="Remove Ministry"
          icon="fa-solid fa-circle-x"
          :loading="btnIsLoading"
          :disabled="btnIsLoading"
          @click="removeMinistry"
        />
      </div>
    </div>
  </popup>
</template>

<script>
import { ref, computed } from '@vue/reactivity';
import Popup from '@/components/ui/Popup.vue';
import Btn from '@/components/ui/Btn.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { sendToastMessage } from '@/utils/helpers';
import CONSTANTS from '@/utils/constants';

export default {
  props: {
    showRemovePopup: {
      required: false,
      type: Boolean,
    },
    removedMinistryName: {
      required: true,
      type: String,
    },
    removedMinistryId: {
      required: true,
      type: Number,
    },
  },
  emit: ['handleToggleRemoveMinistryPopup'],
  components: {
    Popup,
    Btn,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    const btnIsLoading = ref(false);

    const removeMinistry = async () => {
      btnIsLoading.value = true;
      try {
        store.commit('SET_IS_DATA_LOADING', true);
        const currentMinistryId = store.getters['ministries/getCurrentMinistryId'];
        store.dispatch('ministries/removeMinistry', props.removedMinistryId);
        await store.dispatch('currentUser/removeMinistryFromCurrentUser', props.removedMinistryId);
        if (currentMinistryId === props.removedMinistryId) {
          await store.dispatch('ministries/setCurrentMinistryId');
          const ministryId = store.getters['ministries/getCurrentMinistryId'];
          await store.dispatch('ministries/fetchMinistry', ministryId);
          await store.dispatch('staffs/fetchStaffsByMinistryId', { ministryId });
          await router.push({ name: 'MinistryOverview', params: { ministryId } });
        }
        sendToastMessage('success', `${props.removedMinistryName} has been removed!`);
        emit('handleToggleRemoveMinistryPopup', 0, '');
        store.commit('SET_IS_DATA_LOADING', false);
        btnIsLoading.value = false;
        // toast message
      } catch (error) {
        emit('handleToggleRemoveMinistryPopup', 0, '');
        store.commit('SET_IS_DATA_LOADING', false);
        btnIsLoading.value = false;
        if (error.message !== CONSTANTS.ERRORS.MINISTRY_NO_AFFILIATION) {
          sendToastMessage('error', CONSTANTS.ERRORS.REMOVE_MINISTRY);
        }
      }
    };

    const removeMessage = computed(() => {
      return `Are you sure you want to disconnect with <strong>${props.removedMinistryName}</strong>?`;
    });

    const handleToggleRemoveMinistryPopup = () => {
      emit('handleToggleRemoveMinistryPopup', 0, '');
    };

    return {
      btnIsLoading,
      removeMinistry,
      removeMessage,
      handleToggleRemoveMinistryPopup,
    };
  },
};
</script>

<style lang="scss" scoped>
.popup {
  text-align: center;
  padding: 20px;

  h2 {
    color: $purple-dark;
    padding-bottom: 30px;
  }

  p {
    padding-bottom: 30px;
  }

  .cta {
    font-size: 16px;
    padding-bottom: 40px;
  }

  .buttons {
    display: flex;
    justify-content: center;

    button.btn {
      min-height: 40px;
      min-width: 160px;

      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }
    }
  }
}
h2.heading {
  font-size: 24px;
  color: $purple-dark;
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: left;

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    span {
      &:last-child {
        color: $blue;
        font-size: 18px;
        padding-left: 10px;
      }
    }
  }
}
</style>
