<template>
  <div class="photo">
    <div
      v-if="currentUserImageUrl"
      class="profile-image"
      link
      :style="{ backgroundImage: `url(${currentUserImageUrl})` }"
    ></div>
    <person-small-placeholder v-else />
  </div>
</template>

<script>
import personSmallPlaceholder from '@/components/svg/PersonSmallPlaceholder.vue';
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';

export default {
  components: {
    personSmallPlaceholder,
  },
  setup() {
    const store = useStore();

    const currentUserImageUrl = computed(() => {
      return store.getters['currentUser/getCurrentUserImageUrl'];
    });
    return { currentUserImageUrl };
  },
};
</script>

<style lang="scss" scoped>
.photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid $purple-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $purple-light;
  overflow: hidden;

  .profile-image {
    height: 100%;
    width: 100%;
    background-clip: padding-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  svg {
    max-width: 30px;
    max-height: 30px;
  }
}
</style>
