import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Datepicker from '@vuepic/vue-datepicker';

createApp(App)
  .use(store)
  .use(router)
  // .component('Datepicker', Datepicker)
  .directive('focus', {
    // When the bound element is mounted into the DOM...
    mounted(el) {
      // Focus the element
      el.focus();
    },
  })
  .mount('#app');
