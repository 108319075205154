<template>
  <div class="error-message-container">
    <div class="error-message" v-if="validationStatus && validationStatus.$error">
      {{ customErrorMessage ? customErrorMessage : validationStatus.$errors[0].$message }}
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity';

export default {
  name: 'ErrorMessage',
  props: {
    validationStatus: {
      type: Object,
      required: false,
    },
    customErrorMessage: {
      required: false,
      type: String,
    },
  },
  setup(props, { emit }) {
    return {};
  },
};
</script>

<style scoped lang="scss">
.error-message-container {
  text-align: left;
  min-height: 20px;
  margin-bottom: 10px;
  margin-top: 5px;

  .error-message {
    color: $red;
    font-size: 15px;
    line-height: 20px;
  }
}
</style>
