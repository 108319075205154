import axios from 'axios';
import router from './router';
import store from './store';
import { sendToastMessage } from '@/utils/helpers';

const { apiHost } = window.env ? window.env : { apiHost: 'stage-api.newmanministry.com' };

const cancelTokenSource = axios.CancelToken;
const http = axios.create({
  baseURL: `https://${apiHost}/rest/`,
  headers: {
    Accept: 'application/json, text/plain',
    'Content-Type': 'application/json',
  },
});
http.interceptors.request.use((request) => {
  const token = localStorage.getItem('jwtToken');
  if (token) {
    request.headers.Authorization = `Bearer ${token}`; // eslint-disable-line
  }
  return request;
});
http.interceptors.response.use(
  (response) => {
    switch (response.status) {
      case 200: // OK
      case 201: // Created
      case 204: // No Content
      default:
        return Promise.resolve(response);
    }
  },
  async (error) => {
    if (!error.response) return Promise.reject(error);
    switch (error.response.status) {
      case 401: // Not Authorized
        await store.dispatch('auth/logout', false);
        router.push({ name: 'Login', query: { redirect: window.location.pathname } });
        return Promise.reject(error);
      default:
        return Promise.reject(error);
    }
  }
);

export default http;
export { apiHost, cancelTokenSource };
