<template>
  <div class="ministry-list" @click.stop="">
    <div class="selected-ministry" @click="handleDropdownToggle(headerMinistryDropdownId)">
      <span>{{ currentMinistryName }}</span>
      <i class="fa-solid fa-circle-caret-down"></i>
    </div>
    <div
      class="ministry-dropdown"
      :style="[visiblePopupId === headerMinistryDropdownId ? { display: 'block' } : {}]"
    >
      <ul class="ministries">
        <li
          v-for="ministryWithCollege in currentUserMinistriesWithColleges"
          :key="ministryWithCollege.id"
          :class="{ selected: ministryWithCollege.id === currentMinistryId }"
          @click="handleChangeMinistry(ministryWithCollege.id)"
        >
          <div class="ministry">
            <span>{{ ministryWithCollege.name }}</span>
            <ul class="colleges">
              <li v-for="(collegeName, i) of ministryWithCollege.collegesName.slice(0, 5)" :key="i">
                {{ collegeName }}
              </li>
              <li v-if="ministryWithCollege.totalCollegeCount > 5">
                {{ `+${ministryWithCollege.totalCollegeCount - 5} more` }}
              </li>
            </ul>
          </div>
          <div class="remove-icon">
            <i
              class="fa-solid fa-circle-x"
              @click.stop="
                handleToggleRemoveMinistryPopup(ministryWithCollege.id, ministryWithCollege.name)
              "
            ></i>
          </div>
        </li>
        <li>
          <btn
            class="primary"
            icon="fa-solid fa-circle-plus"
            label="Add New Ministry"
            @click="addNewMinistryClick"
          />
        </li>
      </ul>
    </div>
  </div>
  <div class="user-container" @click.stop="">
    <div class="user" @click="handleDropdownToggle(headerUserDropdownId)">
      <person-photo />
      <span>{{ currentUserName }}</span>
      <i class="fa-solid fa-caret-down"></i>
    </div>
    <div
      class="user-dropdown"
      :style="[visiblePopupId === headerUserDropdownId ? { display: 'block' } : {}]"
    >
      <ul>
        <li @click="goProfile">
          <i class="fa-solid fa-pen-to-square"></i>
          <span>Edit Profile</span>
        </li>
        <li @click="goChangePassword">
          <i class="fa-solid fa-key"></i>
          <span>Change Password</span>
        </li>
        <li @click="logout">
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
          <span>Logout</span>
        </li>
      </ul>
    </div>
  </div>
  <add-ministry
    :fromHeader="true"
    :showAddPopup="showAddMinistryPopup"
    @handleToggleAddMinistryPopup="handleToggleAddMinistryPopup"
  ></add-ministry>
  <remove-ministry
    :showRemovePopup="showRemoveMinistryPopup"
    :removedMinistryId="removeMinistryInfo.removedMinistryId"
    :removedMinistryName="removeMinistryInfo.removedMinistryName || 'No Ministry Name'"
    @handleToggleRemoveMinistryPopup="handleToggleRemoveMinistryPopup"
  ></remove-ministry>
</template>

<script>
import { ref, computed } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { sendToastMessage } from '@/utils/helpers';
import CONSTANTS from '@/utils/constants';
import { generateUUID } from '@/utils/helpers';
import Btn from '@/components/ui/Btn.vue';
import personPhoto from '@/components/PersonPhoto.vue';
import AddMinistry from '@/components/ministry/AddMinistry.vue';
import RemoveMinistry from '@/components/ministry/RemoveMinistry.vue';

export default {
  name: 'HeaderComp',
  components: {
    Btn,
    personPhoto,
    AddMinistry,
    RemoveMinistry,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const headerMinistryDropdownId = generateUUID();
    const headerUserDropdownId = generateUUID();

    const currentUserMinistriesWithColleges = computed(() => {
      return store.getters['currentUser/getCurrentUserMinistriesWithColleges'];
    });

    const currentMinistryId = computed(() => {
      return store.getters['ministries/getCurrentMinistryId'];
    });

    const currentMinistryName = computed(() => {
      return store.getters['ministries/getCurrentMinistryName'];
    });

    const currentUserName = computed(() => {
      return store.getters['currentUser/getCurrentUserName'];
    });

    const visiblePopupId = computed(() => {
      return store.getters['popups/getVisiblePopupId'];
    });

    const closeDropdown = (popupId) => {
      store.dispatch('popups/popupHide', popupId);
    };

    const goProfile = async () => {
      closeDropdown(headerUserDropdownId);
      router.replace({ name: 'Profile' });
    };

    const goChangePassword = async () => {
      closeDropdown(headerUserDropdownId);
      router.replace({ name: 'ChangePassword' });
    };

    const logout = async () => {
      await store.dispatch('auth/logout');
      router.push({ name: 'Login' });
    };

    const handleDropdownToggle = (popupId) => {
      if (visiblePopupId.value === popupId) {
        store.dispatch('popups/popupHide', visiblePopupId.value);
      } else {
        store.dispatch('popups/popupShow', popupId);
      }
    };

    const handleChangeMinistry = async (ministryId) => {
      try {
        closeDropdown(headerMinistryDropdownId);
        store.commit('SET_IS_DATA_LOADING', true);
        await store.dispatch('ministries/fetchMinistry', ministryId);
        await store.dispatch('ministries/setCurrentMinistryId', ministryId);
        await store.dispatch('staffs/fetchStaffsByMinistryId', { ministryId });
        store.commit('students/SET_INITIAL_STATE');
        store.commit('people/SET_INITIAL_PEOPLE_STATE');
        await router.push({ name: 'MinistryOverview', params: { ministryId } });
        store.commit('SET_IS_DATA_LOADING', false);
      } catch (error) {
        console.error(error);
        store.commit('SET_IS_DATA_LOADING', false);
        sendToastMessage('error', CONSTANTS.ERRORS.GENERIC_LOADING);
      }
    };

    const showAddMinistryPopup = ref(false);

    const handleToggleAddMinistryPopup = () => {
      showAddMinistryPopup.value = !showAddMinistryPopup.value;
    };

    const showRemoveMinistryPopup = ref(false);
    const removeMinistryInfo = ref({ removedMinistryId: 0, removedMinistryName: '' });
    const handleToggleRemoveMinistryPopup = (removedMinistryId, removedMinistryName) => {
      removeMinistryInfo.value = { removedMinistryId, removedMinistryName };
      handleDropdownToggle(headerMinistryDropdownId.value);
      showRemoveMinistryPopup.value = !showRemoveMinistryPopup.value;
    };

    const addNewMinistryClick = () => {
      handleToggleAddMinistryPopup();
      handleDropdownToggle(headerMinistryDropdownId.value);
    };

    return {
      currentUserMinistriesWithColleges,
      currentMinistryName,
      currentMinistryId,
      currentUserName,
      goProfile,
      goChangePassword,
      logout,
      visiblePopupId,
      handleDropdownToggle,
      handleChangeMinistry,
      addNewMinistryClick,
      closeDropdown,
      headerMinistryDropdownId,
      headerUserDropdownId,
      showAddMinistryPopup,
      handleToggleAddMinistryPopup,
      showRemoveMinistryPopup,
      removeMinistryInfo,
      handleToggleRemoveMinistryPopup,
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: inherit;
}
header {
  display: flex;
}
.ministry-list {
  position: relative;

  .selected-ministry {
    font-family: $font-heading;
    font-weight: bold;
    font-size: 26px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    transition: all 0.3s;
    cursor: pointer;

    i {
      font-size: 26px;
      color: $purple-dark;
      padding-left: 20px;
    }
  }

  .ministry-dropdown {
    display: none;
    background-color: #ffffff;
    position: absolute;
    top: 50px;
    left: 0;
    border: 1px solid #aaaaaa;
    border-radius: 9px;
    box-shadow: 0px 3px 10px rgba(70, 45, 123, 0.09);
    z-index: 2;
    width: 100%;
    min-width: 500px;

    .selected {
      background-color: $purple-light;
    }

    ul.ministries {
      list-style-type: none;
      width: 100%;

      > li {
        border-bottom: 1px solid #aaaaaa;
        padding: 20px 30px;
        transition: all 0.3s;
        display: flex;
        align-items: center;

        &:first-child {
          border-top-left-radius: 9px;
          border-top-right-radius: 9px;
        }

        .remove-icon {
          width: 60px;
          text-align: center;

          i {
            display: none;
            font-size: 24px;
            color: $icon-gray;
            transition: all 0.3s;

            &:hover {
              color: $red;
            }
          }
        }

        &:not(:last-child) {
          cursor: pointer;

          &:hover {
            background-color: $purple-light;

            .remove-icon {
              i {
                display: inline-block;
              }
            }
          }
        }

        &:last-child {
          border-bottom: 0;
        }

        .ministry {
          flex: 1;

          span {
            display: block;
            font-family: $font-heading;
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            color: $purple-dark;
            padding-bottom: 10px;
          }

          ul.colleges {
            list-style-type: none;
            width: 100%;
            padding-left: 20px;

            li {
              font-size: 15px;
              font-style: italic;
              line-height: 18px;
              padding-bottom: 5px;
              color: #626262;

              &:last-child {
                padding-bottom: 0;
              }
            }
          }
        }

        button.btn {
          min-width: 240px;
          min-height: 44px;

          &:deep(i) {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.user-container {
  position: relative;

  .user {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding-left: 15px;
      font-size: 16px;
      flex: 1;
    }

    i {
      padding-left: 15px;
      font-size: 20px;
    }
  }

  .user-dropdown {
    display: none;
    background-color: #ffffff;
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 2;
    width: 100%;
    min-width: 230px;
    border: 1px solid #aaaaaa;
    border-radius: 9px;
    box-shadow: 0px 3px 10px rgba(70, 45, 123, 0.09);

    ul {
      li {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 15px 10px;
        transition: all 0.3s;
        border-bottom: 1px solid #aaaaaa;

        &:first-child {
          border-top-left-radius: 9px;
          border-top-right-radius: 9px;
        }

        &:last-child {
          border-bottom: 0;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
        }

        i {
          width: 50px;
          text-align: center;
          font-size: 20px;
          color: $purple-dark;
        }

        span {
          flex: 1;
          font-size: 16px;
        }

        &:hover {
          background-color: $purple-light;
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .ministry-list {
    .selected-ministry {
      font-size: 22px;
    }
  }
}
</style>
