<template>
  <div class="loading-ring">
    <div :style="style"></div>
    <div :style="style"></div>
    <div :style="style"></div>
    <div :style="style"></div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const style = {
      borderColor: `${props.color || '#000000'} transparent transparent transparent`,
    };
    return { style };
  },
};
</script>

<style scoped>
.loading-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.loading-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid #000000;
  border-radius: 50%;
  animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.loading-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
