let popups = [];

const getInitialState = () => {
  // reset popups
  popups.length = 0;

  return {
    popups,
    showConfirmationPopup: false,
  };
};

export default {
  namespaced: true,
  state: getInitialState,
  getters: {
    getVisiblePopupObj: (state) => {
      let visiblePopup = state.popups.find((a) => {
        return a.visible === true;
      });
      return visiblePopup ? visiblePopup : {};
    },
    getVisiblePopupId: (state) => {
      let visiblePopup = state.popups.find((a) => {
        return a.visible === true;
      });
      return visiblePopup ? visiblePopup.id : '';
    },
    getUnsavedChangesPopup: (state) => {
      let unsavedChangesPopup = state.popups.find((a) => {
        return a.hasUnsavedChanges === true;
      });
      return unsavedChangesPopup ? unsavedChangesPopup.id : false;
    },
    getShowConfirmationPopup: (state) => {
      return state.showConfirmationPopup;
    },
  },
  mutations: {
    SET_INITIAL_STATE: (state) => {
      Object.assign(state, getInitialState());
    },
    SET_POPUP_VISIBLE: (state, popupId) => {
      const popupIdx = popups.findIndex((popup) => popup.id === popupId);
      if (popupIdx === -1) {
        state.popups.push({
          id: popupId,
          visible: true,
          hasUnsavedChanges: false,
        });
      }
      state.popups.forEach((popup) => {
        if (popup.id === popupId) {
          popup.visible = true;
        } else {
          popup.visible = false;
        }
      });
    },
    SET_POPUPS_CLOSE_ALL: (state) => {
      state.popups.forEach((popup) => {
        popup.visible = false;
        popup.hasUnsavedChanges = false;
      });
    },
    SET_POPUP_UNSAVED_CHANGES: (state, { popupId, hasChanges }) => {
      state.popups.forEach((popup) => {
        if (popup.id === popupId) {
          popup.hasUnsavedChanges = hasChanges;
        }
      });
    },
    SET_POPUP_TOGGLE_UNSAVED_CHANGES_POPUP: (state, showPopupVal) => {
      state.showConfirmationPopup = showPopupVal;
    },
  },
  actions: {
    popupHandleUnsavedChanges({ commit, getters }) {
      commit('SET_POPUP_TOGGLE_UNSAVED_CHANGES_POPUP', true);
    },
    popupShow({ commit, getters, dispatch }, popupId) {
      if (getters.getUnsavedChangesPopup) {
        dispatch('popupHandleUnsavedChanges');
      } else {
        commit('SET_POPUP_VISIBLE', popupId);
      }
    },
    popupHide({ commit, getters, dispatch }, popupId) {
      if (getters.getUnsavedChangesPopup) {
        dispatch('popupHandleUnsavedChanges');
      } else {
        commit('SET_POPUPS_CLOSE_ALL');
      }
    },
    popupUnsavedChanges({ commit }, { popupId, hasChanges }) {
      // hasChanges can be true or false
      // example usage in component: store.dispatch('popupUnsavedChanges', { popupId, hasChanges: true });
      commit('SET_POPUP_UNSAVED_CHANGES', { popupId: popupId, hasChanges });
    },
    popupHardClose({ dispatch }, popupId) {
      dispatch('popupUnsavedChanges', {
        popupId: popupId,
        hasChanges: false,
      });
      dispatch('popupHide', popupId);
    },
  },
};
