import { createRouter, createWebHistory } from 'vue-router';
import { default as store } from '../store';
import { ref, computed } from '@vue/reactivity';
import { sendToastMessage } from '@/utils/helpers';
import CONSTANTS from '@/utils/constants';

import Root from '../views/Root.vue';

const isLoading = computed(() => store.getters.getIsDataLoading);

const nonApprovedMinisterGuard = () => {
  const ministryAffiliationNonActive = computed(() =>
    store.getters['currentUser/getCurrentMinistryAffiliationStatus'] === 'ACTIVE' ? false : true
  );
  if (ministryAffiliationNonActive.value && !isLoading.value) {
    sendToastMessage('error', CONSTANTS.ERRORS.MINISTRY_NOT_MINISTERS);
    return false;
  } else {
    return true;
  }
};

const routes = [
  {
    path: '/',
    name: 'Root',
    component: Root,
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuthenticated']) {
        // if user has ministry affiliations
        if (localStorage.getItem('currentMinistryId') !== 'null') {
          next('/ministry/' + localStorage.getItem('currentMinistryId'));
        } else {
          next('/connect-ministry');
        }
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    meta: { notLoggedInView: true },
    component: () => import(/* webpackChunkName: "login" */ '../views/signup-login/Login.vue'),
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    meta: { notLoggedInView: true },
    component: () => import(/* webpackChunkName: "sign-up" */ '../views/signup-login/SignUp.vue'),
  },
  {
    path: '/create-password/app',
    name: 'AppCreatePassword',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "app-create-password" */ '../views/signup-login/AppCreatePassword.vue'
      ),
  },
  {
    path: '/app-link-expired',
    name: 'AppLinkExpired',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "app-link-expired" */ '../views/signup-login/AppLinkExpired.vue'),
  },
  {
    path: '/create-password',
    name: 'CreatePassword',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "create-password" */ '../views/signup-login/CreatePassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () =>
      import(/* webpackChunkName: "reset-password" */ '../views/signup-login/ResetPassword.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "forgot-password" */ '../views/signup-login/ForgotPassword.vue'),
  },
  {
    path: '/link-expired',
    name: 'LinkExpired',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "forgot-password" */ '../views/signup-login/LinkExpired.vue'),
  },
  {
    path: '/connect-ministry',
    name: 'ConnectMinistry',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "connect-ministry" */ '../components/ministry/ConnectMinistry.vue'
      ),
  },
  {
    path: '/legacy-redirect',
    name: 'LegacyRedirect',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "connect-ministry" */ '../views/LegacyRedirect.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "current-user-profile" */ '../views/UserProfile.vue'),
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "change-password" */ '../views/ChangePassword.vue'),
  },
  {
    path: '/ministry/:ministryId',
    name: 'MinistryContainer',
    meta: { requiresAuth: true, breadCrumb: 'Ministry Details' },
    props: (route) => ({
      ministryId: Number(route.params.ministryId),
    }),
    component: () =>
      import(
        /* webpackChunkName: "ministry-container" */ '../views/containers/MinistryContainer.vue'
      ),
    children: [
      {
        path: '',
        name: 'MinistryOverview',
        component: () =>
          import(/* webpackChunkName: "ministry-overview" */ '../views/MinistryOverview.vue'),
      },
      {
        path: 'staff',
        name: 'StaffContainer',
        meta: { nonApprovedMinisterGuard: true, breadCrumb: 'Staff List' },
        component: () =>
          import(
            /* webpackChunkName: "staff-container" */ '../views/containers/StaffContainer.vue'
          ),
        children: [
          {
            path: '',
            name: 'Staff',
            component: () => import(/* webpackChunkName: "staff" */ '../views/Staff.vue'),
          },
          {
            path: ':staffId',
            name: 'StaffProfile',
            props: (route) => ({
              staffId: Number(route.params.staffId),
            }),
            component: () =>
              import(/* webpackChunkName: "staff-profile" */ '../views/StaffProfile.vue'),
          },
        ],
      },
      {
        path: 'college',
        name: 'CollegeContainer',
        meta: { nonApprovedMinisterGuard: true, breadCrumb: 'Colleges Served' },
        component: () =>
          import(
            /* webpackChunkName: "college-container" */ '../views/containers/CollegeContainer.vue'
          ),
        children: [
          {
            path: '',
            name: 'College',
            component: () => import(/* webpackChunkName: "college" */ '../views/College.vue'),
          },
          {
            path: ':collegeId',
            name: 'CollegeProfile',
            props: (route) => ({
              collegeId: Number(route.params.collegeId),
            }),
            component: () =>
              import(/* webpackChunkName: "college-profile" */ '../views/CollegeProfile.vue'),
          },
        ],
      },
      {
        path: 'students',
        name: 'StudentContainer',
        meta: { nonApprovedMinisterGuard: true, breadCrumb: 'Student List' },
        component: () =>
          import(
            /* webpackChunkName: "student-container" */ '../views/containers/StudentContainer.vue'
          ),
        children: [
          {
            path: '',
            name: 'Students',
            component: () => import(/* webpackChunkName: "student" */ '../views/Students.vue'),
          },
          {
            path: ':studentId',
            name: 'StudentProfile',
            props: (route) => ({
              studentId: Number(route.params.studentId),
            }),
            component: () =>
              import(/* webpackChunkName: "student-profile" */ '../views/StudentProfile.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: (to, from) => {
    // student profile -> student list
    if (to.name === 'Students' && from.name === 'StudentProfile') {
      return {
        el: `#id-${from.params.studentId}`,
        top: 70,
      };
    }
    // staff profile -> staff list
    if (to.name === 'Staff' && from.name === 'StaffProfile') {
      return {
        el: `#id-${from.params.staffId}`,
        top: 20,
      };
    }
    return { top: 0 };
  },
  routes,
});

router.beforeEach((to, from) => {
  // Auth
  // if (from.query?.redirect && to.path !== '/login' && store.getters['auth/isAuthenticated']) {
  //   return {
  //     path: `/ministry/20086${from.query.redirect}`,
  //   };
  //   // return from.query.redirect;
  // }
  if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
    return {
      name: 'Login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    };
  }
  // notLoggedInView
  if (to.meta.notLoggedInView && store.getters['auth/isAuthenticated']) {
    return {
      name: 'Root',
    };
  }
  // Campus Minister
  if (to.meta.nonApprovedMinisterGuard) {
    return nonApprovedMinisterGuard();
  }
  return null;
});

export default router;
