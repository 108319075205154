import http from '@/api';
import CONSTANTS from '@/utils/constants';
import { getPrimaryCity, generateApiSearchQuery } from '@/utils/helpers';

const getInitialState = () => ({
  diocese: [],
});

export default {
  namespaced: true,
  state: getInitialState,
  getters: {
    getDioceseForList: (state) => {
      const dioceseList =
        state.diocese.map((diocese) => ({
          id: diocese.id,
          name: diocese.name || 'No Name',
          city: getPrimaryCity(diocese) || '',
        })) || [];
      return dioceseList;
    },
  },
  mutations: {
    SET_INITIAL_STATE: (state) => {
      Object.assign(state, getInitialState());
    },
    SET_DIOCESE: (state, diocese) => {
      state.diocese = diocese;
    },
  },
  actions: {
    async fetchDiocese({ getters, commit }) {
      try {
        const response = await http.get(`diocese?count=999`);
        commit('SET_DIOCESE', response.data.results);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async dioceseAutocomplete({}, searchQuery) {
      let apiQuery = generateApiSearchQuery(searchQuery, ['name', 'city', 'state']);
      const searchParams = `/diocese?search=${apiQuery}`;
      try {
        const result = await http.get(searchParams);
        return result.data;
      } catch (error) {
        throw error;
      }
    },
  },
};
