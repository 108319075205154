import http from '@/api';
import {
  getImageUrl,
  updateValue,
  generateApiSearchQuery,
  getPrimaryAddress,
} from '@/utils/helpers';
import CONSTANTS from '@/utils/constants';

const getInitialState = () => ({
  colleges: {},
});

export default {
  namespaced: true,
  state: getInitialState,
  getters: {
    getCollegeImageGuidById:
      (state) =>
      ({ ministryId, collegeId }) => {
        const collegeObj = state.colleges?.[ministryId]?.find(
          (college) => college.id === collegeId
        );
        return collegeObj?.primaryImageGuid || '';
      },
    getCollegeImageUrlById:
      (state) =>
      ({ ministryId, collegeId }) => {
        const collegeObj = state.colleges?.[ministryId]?.find(
          (college) => college.id === collegeId
        );
        return getImageUrl(collegeObj?.primaryImageGuid);
      },
    getCollegeContactIdById:
      (state) =>
      ({ ministryId, collegeId }) => {
        const collegeObj = state.colleges?.[ministryId]?.find(
          (college) => college.id === collegeId
        );
        return collegeObj?.contactId || 0;
      },
    getCollegeObjById:
      (state) =>
      ({ ministryId, collegeId }) => {
        const collegeObj = state.colleges?.[ministryId]?.find(
          (college) => college.id === collegeId
        );
        return collegeObj || {};
      },
    getCollegeNameById:
      (state) =>
      ({ ministryId, collegeId }) => {
        const collegeObj = state.colleges?.[ministryId]?.find(
          (college) => college.id === collegeId
        );
        return collegeObj?.name || '';
      },
    getCollegeAddressById:
      (state) =>
      ({ ministryId, collegeId }) => {
        const collegeObj = state.colleges?.[ministryId]?.find(
          (college) => college.id === collegeId
        );
        return getPrimaryAddress(collegeObj);
      },
    getCollegesByMinistryId: (state) => (ministryId) => state.colleges[ministryId] || [],
    getCollegeListCarouselById: (state) => (ministryId) => {
      const collegeList =
        state.colleges[ministryId]?.map((college) => ({
          id: college.id,
          imageUrl: getImageUrl(college.primaryImageGuid),
          name: college.name,
          sortOrder: college.sortOrder || 0,
        })) || [];
      return collegeList.sort((a, b) => a.sortOrder - b.sortOrder);
    },
    getCollegeListById: (state) => (ministryId) => {
      const collegeList =
        state.colleges[ministryId]?.map((college) => ({
          id: college.id,
          imageUrl: getImageUrl(college.primaryImageGuid),
          name: college.name,
          contactName: college.contactName || '',
          contactId: college.contactId || null,
          sortOrder: college.sortOrder || 0,
        })) || [];
      return collegeList.sort((a, b) => a.sortOrder - b.sortOrder);
    },
  },
  mutations: {
    SET_INITIAL_STATE: (state) => {
      Object.assign(state, getInitialState());
    },
    SET_COLLEGES: (state, { ministryId, colleges }) => {
      state.colleges = { ...state.colleges, [ministryId]: colleges };
    },
    PUSH_TO_COLLEGE: (state, { ministryId, colleges }) => {
      const oldList = state.colleges[ministryId] || [];
      state.colleges = { ...state.colleges, [ministryId]: [...oldList, ...colleges] };
    },
    REMOVE_COLLEGE_BY_MINISTRY_ID: (state, removedMinistryId) => {
      delete state.colleges[removedMinistryId];
    },
    REMOVE_COLLEGE_FROM_MINISTRY: (state, { collegeId, currentMinistryId }) => {
      const collegeIndexToRemove = state.colleges[currentMinistryId].findIndex(
        (college) => college.id === collegeId
      );
      state.colleges[currentMinistryId].splice(collegeIndexToRemove, 1);
    },
    SET_COLLEGE_PARTIAL: (state, { ministryId, collegeId, collegePartial }) => {
      const collegeObj = state.colleges?.[ministryId]?.find((college) => college.id === collegeId);
      const collegeObjIdx = state.colleges?.[ministryId]?.findIndex(
        (college) => college.id === collegeId
      );
      const newCollegeObj = { ...collegeObj, ...collegePartial };
      Object.assign(state.colleges?.[ministryId][collegeObjIdx], newCollegeObj);
    },
  },
  actions: {
    async fetchCollegesByMinistryId({ getters, commit }, { ministryId, getAllFromZero = false }) {
      const collegesByMinistry = getters.getCollegesByMinistryId(ministryId);
      const start = getAllFromZero ? 0 : collegesByMinistry.length || 0;
      const limit = getAllFromZero ? 999 : collegesByMinistry.length ? 6 : 10;
      const apiCall = `college?start=${start}&count=${limit}&search=ministry_id:${ministryId}&sort=sortOrder ASC`;
      try {
        const {
          data: { results: collegesRes, totalResults: totalCollegeCount },
        } = await http.get(apiCall);
        if (collegesByMinistry.length && !getAllFromZero) {
          await commit('PUSH_TO_COLLEGE', { ministryId, colleges: collegesRes });
        } else {
          await commit('SET_COLLEGES', { ministryId, colleges: collegesRes });
        }
        await commit(
          'pagination/SET_PAGINATION_COLLEGE',
          {
            ministryId,
            totalCollegeCount,
            hasMore: getAllFromZero
              ? collegesRes.length < totalCollegeCount
              : collegesRes.length + collegesByMinistry.length < totalCollegeCount,
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async fetchAllCollegesInCurrentMinistry({ rootGetters, dispatch }) {
      const currentMinistryId = rootGetters['ministries/getCurrentMinistryId'];
      const hasMore = rootGetters['pagination/getCollegeHasMoreByMinistry'](currentMinistryId);
      try {
        if (hasMore) {
          await dispatch('fetchCollegesByMinistryId', {
            ministryId: currentMinistryId,
            getAllFromZero: true,
          });
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateCollegePartial({ commit, getters }, { collegeId, ministryId, field, fieldValue }) {
      try {
        const currentCollege = getters.getCollegeObjById({ collegeId, ministryId });
        const collegePartial = updateValue(
          JSON.parse(JSON.stringify(currentCollege)),
          field,
          fieldValue
        );
        // console.log(collegePartial);
        await http.patch(`college/${collegeId}`, collegePartial);
        commit('SET_COLLEGE_PARTIAL', { ministryId, collegeId, collegePartial });
      } catch (error) {
        console.log(error);
        throw new Error(CONSTANTS.ERRORS.DATA_UPDATE);
      }
    },
    async removeCollegeFromMinistry({ commit, rootGetters }, collegeId) {
      try {
        const currentMinistryId = rootGetters['ministries/getCurrentMinistryId'];
        commit('REMOVE_COLLEGE_FROM_MINISTRY', { collegeId, currentMinistryId });
        commit('pagination/SET_TOTAL_COLLEGE_COUNT_AFTER_REMOVING', currentMinistryId, {
          root: true,
        });
        await http.patch(`college/${collegeId}`, {
          ministryId: 0,
          contactId: 0,
          contactName: null,
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createCollege({ rootGetters, dispatch }, college) {
      try {
        const currentMinistryId = rootGetters['ministries/getCurrentMinistryId'];
        let response = await http.post('college', { ...college, ministryId: currentMinistryId });
        await dispatch('fetchCollegesByMinistryId', {
          ministryId: currentMinistryId,
          getAllFromZero: true,
        });
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async collegesAutocomplete({}, searchQuery) {
      let apiQuery = generateApiSearchQuery(searchQuery, ['name', 'city', 'state']);
      const searchParams = `/college?search=${apiQuery}`;
      try {
        const result = await http.get(searchParams);
        return result.data;
      } catch (error) {
        throw error;
      }
    },
    async addCollegeToMinistry({ rootGetters, commit, dispatch }, college) {
      try {
        const currentMinistryId = rootGetters['ministries/getCurrentMinistryId'];

        const currentUserMinistriesWithColleges =
          rootGetters['currentUser/getCurrentUserMinistriesWithColleges'];
        for (const currentUserMinistry of currentUserMinistriesWithColleges) {
          for (const collegeId of currentUserMinistry.collegesId) {
            if (collegeId === college.id) {
              if (currentUserMinistry.id === currentMinistryId) {
                throw new Error(CONSTANTS.ERRORS.COLLEGE_EXISTS_IN_CURRENT_MINISTRY);
              } else {
                commit('REMOVE_COLLEGE_FROM_MINISTRY', {
                  collegeId,
                  currentMinistryId: currentUserMinistry.id,
                });
                commit(
                  'pagination/SET_TOTAL_COLLEGE_COUNT_AFTER_REMOVING',
                  currentUserMinistry.id,
                  {
                    root: true,
                  }
                );
              }
            }
          }
        }
        await http.patch(`college/${college.id}`, {
          id: college.id,
          ministryId: currentMinistryId,
          contactId: 0,
          contactName: null,
        });
        await dispatch('fetchCollegesByMinistryId', {
          ministryId: currentMinistryId,
          getAllFromZero: true,
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async updateCollegeContact(
      { commit, getters, rootGetters },
      { collegeId, contactId, contactName }
    ) {
      try {
        const ministryId = rootGetters['ministries/getCurrentMinistryId'];
        const currentCollege = getters.getCollegeObjById({ collegeId, ministryId });
        await http.patch(`college/${collegeId}`, { contactId, contactName });
        const updatedCollege = { ...currentCollege };
        updatedCollege.contactId = contactId;
        updatedCollege.contactName = contactName;
        commit('SET_COLLEGE_PARTIAL', { ministryId, collegeId, collegePartial: updatedCollege });
      } catch (error) {
        console.log(error);
        throw new Error(CONSTANTS.ERRORS.DATA_UPDATE);
      }
    },
    async updateCollegeSortOrder(
      { commit, getters, rootGetters, dispatch },
      { ministryId, sortArr, index }
    ) {
      try {
        let collegeListState = getters.getCollegesByMinistryId(ministryId);
        let collegeList = JSON.parse(JSON.stringify(collegeListState));
        // set sort order
        collegeList.forEach((college) => {
          sortArr.forEach((sortId, index) => {
            if (college.id === sortId) {
              let newSortOrder = index + 1;
              college.sortOrder = newSortOrder;
            }
          });
        });
        // sort
        collegeList.sort((a, b) => a.sortOrder - b.sortOrder);

        commit('SET_COLLEGES', { ministryId, colleges: collegeList });

        const allCollegeIds = await http.get(`college/${ministryId}/sortOrder`);
        let allCollegeIdsArr = allCollegeIds?.data;
        let collegeId = sortArr[index];
        let collegeArrFiltered = allCollegeIdsArr.filter((col) => col !== collegeId);

        collegeArrFiltered.splice(index, 0, collegeId);

        // console.log(index);
        // console.log(collegeId);
        // console.log(collegeArrFiltered);

        await http.put(`college/reorder`, collegeArrFiltered);
      } catch (error) {
        console.log(error);
        throw new Error(CONSTANTS.ERRORS.DATA_UPDATE);
      }
    },
  },
};
