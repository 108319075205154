import { apiHost } from '@/api';
import { quillEditor, Quill } from 'vue3-quill';

export const convertQuillToHtml = (delta) => {
  if (delta) {
    console.log('quill conversion');
    let deltaArr = JSON.parse(delta);
    let tempCont = document.createElement('div');
    new Quill(tempCont).setContents({ ops: deltaArr });
    return tempCont.getElementsByClassName('ql-editor')[0].innerHTML;
  }
  return false;
};

export const getRichText = (text) => {
  if (text) {
    if (text.indexOf('[') === 0) {
      return convertQuillToHtml(text);
    } else {
      return text;
    }
  }
  return '';
};

export const getTextLength = (html) => {
  if (html) {
    let temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.innerText.trim().length || 0;
  }
  return 0;
};

export const getImageUrl = (imageGuid) =>
  imageGuid ? `https://${apiHost}/rest/image/${imageGuid}` : '';

export const get = (obj, path, defaultValue = '') => {
  const traverse = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = traverse(/[,[\]]+?/) || traverse(/[,[\].]+?/);
  return result === undefined || result === null || result === obj ? defaultValue : result;
};

export const getPrimary = (obj, key, defaultValue = '') => {
  if (!obj || !Array.isArray(obj)) return defaultValue;
  const primary = obj.find((i) => i.primary === true);
  return get(primary, key, defaultValue);
};

export const getPrimaryObjAndIdx = (obj) => {
  if (!obj || Object.keys(obj).length === 0 || !Array.isArray(obj)) return null;
  const primaryIdx = obj.findIndex((i) => i.primary === true);
  const primaryObj = primaryIdx >= 0 ? obj[primaryIdx] : null;
  return { primaryObj, primaryIdx };
};

export const getCompleteName = (person) => {
  const suffix = get(person, 'suffix');
  suffix = suffix.length && suffix.length > 0 ? ', ' + suffix : '';

  const completeName = `${get(person, 'prefix')} ${get(person, 'firstName')} ${get(
    person,
    'middleName'
  )} ${get(person, 'lastName')}${suffix}`.trim();
  // completeName.replace("  ", "");
  return completeName;
};

export const getFullName = (person) => {
  const f = get(person, 'firstName');
  const l = get(person, 'lastName');
  if (f === '' && l === '') return '';

  return `${get(person, 'firstName')} ${get(person, 'lastName')}`;
};

export const getPrimaryEmail = (entity) => {
  if (entity) return getPrimary(entity.emails, 'email');
};

export const getPrimaryPhone = (entity) => {
  if (entity) {
    let phoneNumber = getPrimary(entity.phoneNumbers, 'number');
    if (phoneNumber && phoneNumber.length) {
      let charArr = phoneNumber.split('');
      let c = charArr.filter((char) => {
        return char !== ' ' && !isNaN(char);
      });
      if (c.length === 10 || c.length === 11) {
        if (c.length === 10) {
          phoneNumber = `(${c[0]}${c[1]}${c[2]}) ${c[3]}${c[4]}${c[5]} - ${c[6]}${c[7]}${c[8]}${c[9]}`;
        } else if (c.length === 11) {
          phoneNumber = `${c[0]} (${c[1]}${c[2]}${c[3]}) ${c[4]}${c[5]}${c[6]} - ${c[7]}${c[8]}${c[9]}${c[10]}`;
        }
      }
    }
    return phoneNumber;
  }
};

export const getPrimaryAddress = (entity) => {
  const address = entity?.addresses?.find((address) => address.primary === true);
  return {
    street1: address?.street1 || '',
    street2: address?.street2 || '',
    city: address?.city || '',
    stateAbbreviation: address?.stateAbbreviation || '',
    stateName: address?.stateName || '',
    postalCode: address?.postalCode || '',
  };
};

export const getStudentStatsQuery = (queryObj) => {
  const { keyword, collegeId, year, ministryId } = queryObj;
  let queryString = `${
    keyword ? `(name:"${keyword}" OR email:"${keyword}") AND ` : ''
  }ministryId:${ministryId}`;
  if (year !== 'all') {
    const yearParam = `AND highSchoolGraduationYear:${year}`;
    queryString = `${queryString} ${yearParam}`;
  }
  if (collegeId !== 'all') {
    queryString = `${queryString} AND collegeId:${collegeId}`;
  }
  return queryString;
};

export const getAffiliation = (type) => (obj) =>
  obj?.affiliations.find((a) => a.entityType === type);

export const getHighSchool = getAffiliation('HIGH_SCHOOL');
export const getParish = getAffiliation('PARISH');
export const getEntityName = (affiliation) => get(affiliation, 'entityName');
export const getCollege = getAffiliation('COLLEGE');

export const getPrimaryStreet1 = (entity) => getPrimary(entity.addresses, 'street1');
export const getPrimaryStreet2 = (entity) => getPrimary(entity.addresses, 'street2');
export const getPrimaryCity = (entity) => getPrimary(entity.addresses, 'city');
export const getPrimaryStateId = (entity) => getPrimary(entity.addresses, 'stateId');
export const getPrimaryState = (entity) => getPrimary(entity.addresses, 'state');
export const getPrimaryPostalCode = (entity) => getPrimary(entity.addresses, 'postalCode');

export const getAffiliationOnEntityId = (obj, entityId) => {
  // debugger;
  return obj?.affiliations?.find((x) => x.entityId === entityId) || null;
};

export const getRoleOnEntityId = (obj, entityId) => {
  // debugger;
  return obj?.affiliations?.find((x) => x.entityId === entityId) || null;

  person.affiliations.find(
    (aff) => aff.entityId === currentMinistryId.value && aff.roleName === 'Campus Minister'
  );
};
