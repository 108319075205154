import http from '@/api';

const getInitialState = () => ({
  pagination: {},
});

export default {
  namespaced: true,
  state: getInitialState,
  getters: {
    getCollegeHasMoreByMinistry: (state) => (ministryId) =>
      state.pagination[ministryId] ? state.pagination[ministryId].college?.hasMore : true,
    getTotalCollegeCountByMinistry: (state) => (ministryId) =>
      state.pagination[ministryId] ? state.pagination[ministryId].college?.totalCollegeCount : 0,
    getStaffHasMoreByMinistry: (state) => (ministryId) =>
      state.pagination[ministryId] ? state.pagination[ministryId].staff?.hasMore : true,
    getTotalStaffCountByMinistry: (state) => (ministryId) =>
      state.pagination[ministryId] ? state.pagination[ministryId].staff?.totalStaffCount : 0,
  },
  mutations: {
    SET_INITIAL_STATE: (state) => {
      Object.assign(state, getInitialState());
    },
    SET_PAGINATION_COLLEGE: (state, { ministryId, totalCollegeCount, hasMore }) => {
      state.pagination = {
        ...state.pagination,
        [ministryId]: {
          college: { totalCollegeCount, hasMore },
          staff:
            state.pagination[ministryId] && state.pagination[ministryId].staff
              ? state.pagination[ministryId].staff
              : {},
        },
      };
    },
    SET_PAGINATION_STAFF: (state, { ministryId, totalStaffCount, hasMore }) => {
      state.pagination = {
        ...state.pagination,
        [ministryId]: {
          staff: { totalStaffCount, hasMore },
          college:
            state.pagination[ministryId] && state.pagination[ministryId].college
              ? state.pagination[ministryId].college
              : {},
        },
      };
    },
    SET_TOTAL_COLLEGE_COUNT_AFTER_REMOVING: (state, ministryId) => {
      state.pagination[ministryId].college.totalCollegeCount -= 1;
    },
    SET_TOTAL_STAFF_COUNT_AFTER_REMOVING: (state, ministryId) => {
      state.pagination[ministryId].staff.totalStaffCount -= 1;
    },
    REMOVE_PAGINATION_BY_MINISTRY_ID: (state, removedMinistryId) => {
      delete state.pagination[removedMinistryId];
    },
    // SET_COLLEGE_TOTAL_COUNT: (state, { ministryId, totalCollegeCount }) => {
    //   state.pagination[ministryId].college.totalCollegeCount = totalCollegeCount;
    // },
    // SET_COLLEGE_HAS_MORE: (state, { ministryId, hasMore }) => {
    //   state.pagination[ministryId].college.hasMore = hasMore;
    // },
  },
  actions: {
    // setCollegeHasMore({ commit }, payload) {
    //   commit('SET_COLLEGE_HAS_MORE', payload);
    // },
    // setTotalCollegeCount({ commit }, payload) {
    //   commit('SET_COLLEGE_TOTAL_COUNT', payload);
    // },
  },
};
