export default {
  ACADEMIC_YEAR_START_MONTH: 5, // Zero indexed (5 = June)
  ITEMS_PER_PAGE: 10,
  ERRORS: {
    CURRENT_USER_NONE: 'No current user exists.',
    MINISTRY_NO_AFFILIATION: 'This user is not affiliated with any ministry.',
    MINISTRY_NOT_MINISTERS: 'Sorry, this page is only available to approved campus ministers.',
    MINISTRY_EXISTING_AFFILIATION: 'This user already has an affiliation with this ministry.',
    CAMPUS_MINISTRY: 'There was an error retrieving Campus Ministry data.',
    COLLEGES: 'There are no colleges associated with this ministry',
    STUDENT_COUNTS: 'There was an error retrieving student count data.',
    REMOVE_MINISTRY: 'There was an error removing the ministry.',
    MINISTRY_ADD: 'There was an error adding ministry.',
    STAFF_REORDER: 'There was an error re-ordering staff.',
    STAFF_DELETE: 'There was an error deleting staff.',
    STAFF_MEMBER_NOT_FOUND: 'Staff member not found',
    COLLEGE_DELETE: 'There was an error deleting college.',
    COLLEGE_ADD: 'There was an error adding college.',
    COLLEGE_CREATE: 'There was an error creating college.',
    COLLEGE_EXISTS_IN_CURRENT_MINISTRY: 'College is already affiliated with this ministry',
    DATA_UPDATE: 'Sorry, there was an error while updating. Try again.',
    FILTER_UPDATE: 'Sorry, there was an error while filtering. Try again.',
    PASSWORD_RESET: 'There was an error changing password.',
    EMAIL_REQUIRED: 'An email is required!',
    LOGIN: 'Invalid username and/or password.',
    GENERIC_LOADING: 'Sorry, something went wrong when loading data.',
    GENERIC_ERROR: 'Sorry, there was an error. Try again.',
    NEWS_EDIT_DISABLED: 'Sorry, you do not have permission to edit this article.',
  },
  SUCCESSES: {
    MINISTRY_REMOVED: 'Ministry has been removed from current user!',
    MINISTRY_ADD: 'New Ministry has been added!',
    MINISTRY_IMAGE_UPDATED: 'Ministry Image has been updated!',
    MINISTRY_IMAGE_REMOVED: 'Ministry Image has been removed!',
    MINISTRY_NAME_UPDATED: 'Ministry Name has been updated!',
    WELCOME_MESSAGE_UPDATED: 'Welcome Message has been updated!',
    MASS_TIMES_UPDATED: 'Mass Times has been updated!',
    CONFESSION_TIMES_UPDATED: 'Confession Times has been updated!',
    ADORATION_TIMES_UPDATED: 'Adoration Times has been updated!',
    ADDRESS_UPDATED: 'Address has been updated!',
    PHONE_NUMBER_UPDATED: 'Phone Number has been updated!',
    EMAIL_UPDATED: 'Email Address has been updated!',
    WEBSITE_UPDATED: 'Website has been updated!',
    SOCIAL_MEDIA_ACCOUNTS_UPDATED: 'Social Media Account(s) has been updated!',
    STAFF_IMAGE_UPDATED: 'Staff Image has been updated!',
    STAFF_IMAGE_REMOVED: 'Staff Image has been removed!',
    STAFF_NAME_UPDATED: 'Staff Name has been updated!',
    STAFF_SORT_ORDER_UPDATED: 'Staff order has been updated!',
    STAFF_MEMBER_ADDED: 'Staff member has been added!',
    USER_INVITED: `An invite has been sent to this user's email address!`,
    JOB_TITLE_UPDATED: 'Job Title has been updated!',
    COLLEGE_IMAGE_UPDATED: 'College Image has been updated!',
    COLLEGE_CONTACT_UPDATED: 'College Contact has been updated!',
    COLLEGE_CONTACT_IS_THE_SAME: 'College Contact saved was the same as before!',
    COLLEGE_IMAGE_REMOVED: 'College Image has been removed!',
    COLLEGE_NAME_UPDATED: 'College Name has been updated!',
    COLLEGE_ADD: 'College has been added to ministry!',
    COLLEGE_CREATE_ADD: 'New College has been created and added to ministry!',
    COLLEGE_SORT_ORDER_UPDATED: 'College order has been updated!',
    STUDENT_NAME_UPDATED: 'Student Name has been updated!',
    STUDENT_IMAGE_UPDATED: 'Student Image has been updated!',
    STUDENT_IMAGE_REMOVED: 'Student Image has been removed!',
    AUTOCOMPLETE: {
      college: 'College has been saved!',
      highSchool: 'High School or Parish has been saved!',
    },
    NOTES_UPDATED: 'Notes have been updated!',
    IMAGE_UPDATED: 'Image has been updated!',
    IMAGE_REMOVED: 'Image has been removed!',
    NAME_UPDATED: 'Name has been updated!',
    NEWS_CREATED: 'News Article has been created!',
    NEWS_HEADLINE_UPDATED: 'News Headline has been updated!',
    NEWS_URL_UPDATED: 'News URL has been updated!',
    NEWS_PUBLISHED_DATE_UPDATED: 'News Published Date has been updated!',
    NEWS_EXPIRATION_DATE_UPDATED: 'News Expiration Date has been updated!',
    NEWS_STORY_UPDATED: 'News Story has been updated!',
    NEWS_PUBLISHED: 'News Story has been published!',
    GROUP_CREATED: 'New Group has been created!',
    GROUP_NAME_UPDATED: 'Group Name has been updated!',
    GROUP_DESCRIPTION_UPDATED: 'Group Description has been updated!',
    GROUP_PRIVACY_UPDATED: 'Group Privacy has been updated!',
    GROUP_MAX_SIZE_UPDATED: 'Group Max Size has been updated!',
    GROUP_LEADER_UPDATED: 'Group Leader has been updated!',
    GROUP_DELETED: 'Group has been deleted!',
    MEMBER_CURRENT_USER_JOINED: 'You have joined the group!',
    MEMBER_ACCEPT: 'Member accepted!',
    MEMBER_DENIED: 'Member denied!',
    MEMBER_REMOVED: 'Member removed!',
    MEMBER_INVITE_SENT: 'Invitation(s) has been sent!',
    MEMBER_INVITE_CANCELLED: 'Invitation has been cancelled!',
    MEMBER_INVITE_DENIED: 'Invitation has been denied!',
  },
  MODULE: {
    STAFF_MEMBER_NOT_FOUND: 'Staff member not found',
    STAFF_MEMBER_EMAIL_EXISTS: 'Member is already a staff in this ministry',
  },
  PHONE_TYPES: ['CELL', 'HOME', 'WORK', 'FAX', 'OTHER'],
  STATE_ABBREVIATIONS: [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
  UNITED_STATES_DATA: [
    { id: 1, name: 'Alabama', latitude: 33.2588817, longitude: -86.8295337, abbreviation: 'AL' },
    { id: 2, name: 'Alaska', latitude: 64.4459613, longitude: -149.680909, abbreviation: 'AK' },
    { id: 3, name: 'Arizona', latitude: 34.395342, longitude: -111.7632755, abbreviation: 'AZ' },
    { id: 4, name: 'Arkansas', latitude: 34.9513, longitude: -92.3809, abbreviation: 'AR' },
    { id: 5, name: 'California', latitude: 36.17, longitude: -119.417931, abbreviation: 'CA' },
    { id: 6, name: 'Colorado', latitude: 39.0646, longitude: -105.3272, abbreviation: 'CO' },
    { id: 7, name: 'Connecticut', latitude: 41.5834, longitude: -72.7622, abbreviation: 'CT' },
    { id: 8, name: 'Delaware', latitude: 39.3498, longitude: -75.5148, abbreviation: 'DE' },
    {
      id: 9,
      name: 'District of Columbia',
      latitude: 38.8964,
      longitude: -77.0262,
      abbreviation: 'DC',
    },
    { id: 10, name: 'Florida', latitude: 27.8333, longitude: -81.717, abbreviation: 'FL' },
    { id: 11, name: 'Georgia', latitude: 32.9866, longitude: -83.6487, abbreviation: 'GA' },
    { id: 12, name: 'Hawaii', latitude: 21.1098, longitude: -157.5311, abbreviation: 'HI' },
    { id: 13, name: 'Idaho', latitude: 44.2394, longitude: -114.5103, abbreviation: 'ID' },
    { id: 14, name: 'Illinois', latitude: 40.3363, longitude: -89.0022, abbreviation: 'IL' },
    { id: 15, name: 'Indiana', latitude: 39.8647, longitude: -86.2604, abbreviation: 'IN' },
    { id: 16, name: 'Iowa', latitude: 42.0046, longitude: -93.214, abbreviation: 'IA' },
    { id: 17, name: 'Kansas', latitude: 38.5111, longitude: -96.8005, abbreviation: 'KS' },
    { id: 18, name: 'Kentucky', latitude: 37.669, longitude: -84.6514, abbreviation: 'KY' },
    { id: 19, name: 'Louisiana', latitude: 31.1801, longitude: -91.8749, abbreviation: 'LA' },
    { id: 20, name: 'Maine', latitude: 44.6074, longitude: -69.3977, abbreviation: 'ME' },
    { id: 21, name: 'Maryland', latitude: 39.0724, longitude: -76.7902, abbreviation: 'MD' },
    { id: 22, name: 'Massachusetts', latitude: 42.2373, longitude: -71.5314, abbreviation: 'MA' },
    { id: 23, name: 'Michigan', latitude: 43.3504, longitude: -84.5603, abbreviation: 'MI' },
    { id: 24, name: 'Minnesota', latitude: 45.7326, longitude: -93.9196, abbreviation: 'MN' },
    { id: 25, name: 'Mississippi', latitude: 32.7673, longitude: -89.6812, abbreviation: 'MS' },
    { id: 26, name: 'Missouri', latitude: 38.4623, longitude: -92.302, abbreviation: 'MO' },
    { id: 27, name: 'Montana', latitude: 46.9048, longitude: -110.3261, abbreviation: 'MT' },
    { id: 28, name: 'Nebraska', latitude: 41.1289, longitude: -98.2883, abbreviation: 'NE' },
    { id: 29, name: 'Nevada', latitude: 38.4199, longitude: -117.1219, abbreviation: 'NV' },
    { id: 30, name: 'New Hampshire', latitude: 43.4108, longitude: -71.5653, abbreviation: 'NH' },
    { id: 31, name: 'New Jersey', latitude: 40.314, longitude: -74.5089, abbreviation: 'NJ' },
    { id: 32, name: 'New Mexico', latitude: 34.8375, longitude: -106.2371, abbreviation: 'NM' },
    { id: 33, name: 'New York', latitude: 42.1497, longitude: -74.9384, abbreviation: 'NY' },
    { id: 34, name: 'North Carolina', latitude: 35.6411, longitude: -79.8431, abbreviation: 'NC' },
    { id: 35, name: 'North Dakota', latitude: 47.5362, longitude: -100.437012, abbreviation: 'ND' },
    { id: 36, name: 'Ohio', latitude: 40.3736, longitude: -82.7755, abbreviation: 'OH' },
    { id: 37, name: 'Oklahoma', latitude: 35.5376, longitude: -96.9247, abbreviation: 'OK' },
    { id: 38, name: 'Oregon', latitude: 44.5672, longitude: -122.1269, abbreviation: 'OR' },
    { id: 39, name: 'Pennsylvania', latitude: 40.5773, longitude: -77.264, abbreviation: 'PA' },
    { id: 40, name: 'Rhode Island', latitude: 41.6772, longitude: -71.5101, abbreviation: 'RI' },
    { id: 41, name: 'South Carolina', latitude: 33.8191, longitude: -80.9066, abbreviation: 'SC' },
    { id: 42, name: 'South Dakota', latitude: 44.2853, longitude: -99.4632, abbreviation: 'SD' },
    { id: 43, name: 'Tennessee', latitude: 35.7449, longitude: -86.7489, abbreviation: 'TN' },
    { id: 44, name: 'Texas', latitude: 31.8160381, longitude: -99.5120986, abbreviation: 'TX' },
    { id: 45, name: 'Utah', latitude: 40.1135, longitude: -111.8535, abbreviation: 'UT' },
    { id: 46, name: 'Vermont', latitude: 44.0407, longitude: -72.7093, abbreviation: 'VT' },
    { id: 47, name: 'Virginia', latitude: 37.768, longitude: -78.2057, abbreviation: 'VA' },
    { id: 48, name: 'Washington', latitude: 47.3917, longitude: -121.5708, abbreviation: 'WA' },
    { id: 49, name: 'West Virginia', latitude: 38.468, longitude: -80.9696, abbreviation: 'WV' },
    { id: 50, name: 'Wisconsin', latitude: 44.2563, longitude: -89.6385, abbreviation: 'WI' },
    { id: 51, name: 'Wyoming', latitude: 42.7475, longitude: -107.2085, abbreviation: 'WY' },
  ],
};
