import * as getHelpers from '@/utils/getHelpers';

export const fieldConfig = {
  accountHolder: {
    isPartOfArray: false,
  },
  imageGuid: {
    isPartOfArray: false,
  },
  primaryImageGuid: {
    isPartOfArray: false,
  },
  name: {
    isPartOfArray: false,
  },
  welcomeMessage: {
    isPartOfArray: false,
  },
  notes: {
    isPartOfArray: false,
  },
  address: {
    isPartOfArray: true,
    fieldArrKey: 'addresses',
    updatePrimaryOnly: true,
    valueIsObject: true,
  },
  number: {
    isPartOfArray: true,
    fieldArrKey: 'phoneNumbers',
    updatePrimaryOnly: true,
  },
  email: {
    isPartOfArray: true,
    fieldArrKey: 'emails',
    updatePrimaryOnly: true,
  },
  socialMediaAccounts: {
    fieldArrKey: 'socialMediaAccounts',
  },
  affiliations: {
    isPartOfArray: false,
  },
  website: {
    isPartOfArray: false,
  },
  massTime: {
    isPartOfArray: false,
  },
  confessionTime: {
    isPartOfArray: false,
  },
  adorationTime: {
    isPartOfArray: false,
  },
  jobTitle: {
    isPartOfArray: false,
  },
};

export const DEFAULT_VALUES = {
  email: {
    email: undefined,
    primary: true,
  },
  number: {
    number: undefined,
    type: 'WORK',
    primary: true,
  },
  address: {
    street1: undefined,
    street2: undefined,
    city: undefined,
    stateId: undefined,
    stateName: undefined,
    stateAbbreviation: undefined,
    postalCode: undefined,
    type: 'BUSINESS',
    primary: true,
    latitude: undefined,
    longitude: undefined,
  },
};

const defaultValuesMinistry = {
  ...DEFAULT_VALUES,
  ...{},
};

const defaultValuesStaff = {
  ...DEFAULT_VALUES,
  ...{},
};

export const updateValue = (currentEntity, field, newValue, id = null, entityType = 'ministry') => {
  let entityDefaultValues = DEFAULT_VALUES;
  if (entityType === 'staff') {
    entityDefaultValues = defaultValuesStaff;
  }

  const entityPartialUpdate = {};

  if (typeof field === 'object') {
    // multiple field values (as an array)
    // example: update first and last name
    // only works when field being updated is not an array
    field.forEach((f, idx) => {
      entityPartialUpdate[f] = newValue[idx];
    });
  } else {
    if (fieldConfig[field]?.isPartOfArray) {
      const fieldArrKey = fieldConfig[field].fieldArrKey;
      const fieldArr = currentEntity[fieldArrKey] || [];

      let idxToUpdate;
      let objToUpdate;
      if (fieldConfig[field].updatePrimaryOnly) {
        const primaryObjAndIdx = getHelpers.getPrimaryObjAndIdx(fieldArr);
        if (primaryObjAndIdx === null) {
          idxToUpdate = 0;
          objToUpdate = entityDefaultValues[field];
        } else {
          idxToUpdate = primaryObjAndIdx.primaryIdx;
          objToUpdate = primaryObjAndIdx.primaryObj;
        }
      } else {
        idxToUpdate = fieldArr.findIndex((item) => item.id === id);
        objToUpdate = fieldArr[idxToUpdate];
      }
      if (!objToUpdate || idxToUpdate < 0) return;

      const updatedObj = fieldConfig[field].valueIsObject
        ? { ...objToUpdate, ...newValue }
        : { ...objToUpdate, [field]: newValue };
      fieldArr.splice(idxToUpdate, 1, updatedObj);

      entityPartialUpdate[fieldArrKey] = fieldArr;
    } else {
      entityPartialUpdate[field] = newValue;
    }
  }

  return entityPartialUpdate;
};
