<template>
  <nav>
    <ul class="main-nav">
      <li
        v-for="(tab, index) in sidebarTabs"
        :key="index"
        @click="onClick(index, tab.label)"
        v-bind="tab"
        :class="{ selected: tab.selected }"
      >
        <i :class="tab.icon"></i>
        <span>{{ tab.label }}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
import { computed } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const ministryId = computed(() => store.getters['ministries/getCurrentMinistryId']);

    const RouteNames = {
      ministry: 'MinistryOverview',
      students: 'Students'
    };

    const selectedTabRoutes = {
      ministry: ['MinistryOverview', 'Staff', 'StaffProfile', 'College', 'CollegeProfile'],
      students: ['Students', 'StudentProfile']
    };

    const routes = computed(() => ({
      Ministry: ministryId.value
        ? { name: RouteNames.ministry, params: { ministryId: ministryId.value } }
        : { name: RouteNames.ministry },
      Students: ministryId.value
        ? {
            name: RouteNames.students,
            params: { ministryId: ministryId.value },
          }
        : { name: RouteNames.students }
    }));

    const sidebarTabs = computed(() => {
      return [
        {
          icon: 'fa-solid fa-objects-column',
          label: 'Ministry',
          selected: selectedTabRoutes.ministry.includes(route.name),
        },
        {
          icon: 'fa-solid fa-book-user',
          label: 'Students',
          selected: selectedTabRoutes.students.includes(route.name),
        }
      ];
    });

    const onClick = (selectedIdx, label) => {
      sidebarTabs.value.forEach((lItem, idx) => {
        lItem.selected = idx === selectedIdx;
      });
      router.push(routes.value[label]);
    };

    return {
      sidebarTabs,
      onClick,
    };
  },
};
</script>

<style lang="scss" scoped>
nav {
  padding-top: 60px;
  ul.main-nav {
    li {
      display: flex;
      align-items: center;
      border-left: 8px solid transparent;
      height: 74px;
      cursor: pointer;
      margin-top: 1px;
      padding-left: 25px;
      transition: all 0.3s;

      i {
        width: 50px;
        text-align: center;
        font-size: 24px;
        color: #6b5895;
      }

      span {
        font-family: $font-heading;
        font-size: 18px;
        font-weight: 600;
        color: #ae9ed0;
      }

      &.selected,
      &:hover {
        background-color: #341d65;
        border-color: $blue;

        i {
          color: $blue;
        }

        span {
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  nav {
    ul.main-nav {
      li {
        flex-wrap: wrap;
        padding-left: 0;
        justify-content: center;
        align-content: center;

        span {
          width: 100%;
          text-align: center;
          font-size: 12px;
          padding-top: 5px;
        }
      }
    }
  }
}
</style>
