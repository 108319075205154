<template>
  <div class="toast-messages-wrapper" v-if="toastMessages.length">
    <TransitionGroup name="fade">
      <div
        class="toast-box-container"
        :class="toastClass(toast.type)"
        v-for="(toast, idx) in toastMessages"
        :key="idx"
        :data-id="toast.id"
      >
        <div class="toast-box">
          <span>{{ toast.message }}</span>
          <div class="close-icon" @click="closeToast(toast.id)">
            <i class="fa-solid fa-xmark"></i>
          </div>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';

export default {
  components: {},
  props: {},
  setup() {
    const store = useStore();

    const toastMessages = computed(() => store.getters['toast/getMessages']);

    const closeToast = (id) => {
      store.dispatch('toast/removeToastMessage', { id });
    };

    const toastClass = (type) => {
      return {
        success: type === 'success',
        error: type === 'error',
        warning: type === 'warning',
        info: type === 'info',
      };
    };

    return {
      closeToast,
      toastMessages,
      toastClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.toast-messages-wrapper {
  position: fixed;
  top: 35px;
  left: 50%;
  transform: translate(calc(-50% + 125px), 0);
  padding: 0;
  z-index: 999;

  .toast-box-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    color: #000000;
    // border: 1px solid #a3a3a3;

    &:last-child {
      margin-bottom: 0;
    }

    &.success {
      .toast-box {
        color: #596d51;
        background-color: $color-success-toast;
        border-color: #596d51;

        .close-icon i {
          color: #596d51;
        }
      }
    }

    &.error {
      .toast-box {
        color: #a62e2f;
        background-color: $color-error-toast;
        border-color: #a62e2f;

        .close-icon i {
          color: #a62e2f;
        }
      }
    }

    &.warning {
      .toast-box {
        color: #916d2f;
        background-color: $color-warning-toast;
        border-color: #916d2f;

        .close-icon i {
          color: #916d2f;
        }
      }
    }

    &.info {
      .toast-box {
        color: #376a89;
        background-color: $color-info-toast;
        border-color: #376a89;

        .close-icon i {
          color: #376a89;
        }
      }
    }
  }

  .toast-box {
    // min-width: 600px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
    border: 1px solid transparent;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    align-items: center;

    span {
      // flex: 1;
      // text-align: center;
      font-size: 18px;
      font-weight: 500;
    }

    .close-icon {
      margin-left: 20px;
      cursor: pointer;

      i {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .toast-messages-wrapper {
    transform: translate(calc(-50% + 50px), 0);
  }
}
</style>
