<template>
  <div class="loading" :style="customStyle">
    <div class="center">
      <div class="spinner"></div>
      <div class="text">{{ message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Loading...',
    },
    customStyle: Object,
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  color: $purple-dark;
  font-size: 40px;
  z-index: 1000;
  background-color: #ffffff;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  border: 6px solid $purple-dark;
  border-top: 6px solid #eeeeee;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.75s linear infinite;
}
.text {
  font-size: 30px;
  margin-left: 20px;
  text-align: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
