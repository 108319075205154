import { createLogger, createStore } from 'vuex';
import authModule from './modules/auth.js';
import popupsModule from './modules/popups.js';
import currentUserModule from './modules/currentUser.js';
import ministriesModule from './modules/ministries.js';
import collegesModule from './modules/colleges';
import staffsModule from './modules/staffs.js';
import paginationModule from './modules/pagination.js';
import toastModule from './modules/toast.js';
import studentsModule from './modules/students.js';
import dioceseModule from './modules/diocese.js';
import peopleModule from './modules/people.js';

import http from '@/api';

const plugins = process.env.NODE_ENV === 'production' ? [] : [createLogger()];

const getInitialState = () => ({
  isDataLoading: false,
  isInitialDataLoaded: false,
  fromApp: false,
});

export default createStore({
  plugins,
  strict: true,
  state: getInitialState,
  getters: {
    getIsDataLoading: (state) => state.isDataLoading,
    getIsInitialDataLoaded: (state) => state.isInitialDataLoaded,
    getIsFromApp: (state) => state.fromApp,
  },
  mutations: {
    SET_INITIAL_STATE: (state) => {
      Object.assign(state, getInitialState());
    },
    SET_IS_DATA_LOADING: (state, value) => {
      state.isDataLoading = value;
    },
    SET_IS_INITIAL_DATA_LOADED: (state, value) => {
      state.isInitialDataLoaded = value;
    },
    SET_IS_FROM_APP: (state, value) => {
      state.fromApp = value;
    },
  },
  actions: {
    async loadBasicData({ getters, commit, dispatch }) {
      try {
        commit('SET_IS_DATA_LOADING', true);
        await dispatch('currentUser/fetchCurrentUser');
        await dispatch('ministries/setCurrentMinistryId');
        const ministryId = getters['ministries/getCurrentMinistryId'];
        await dispatch('ministries/fetchMinistry', ministryId);
        for (const userMinistry of getters['currentUser/getCurrentUserMinistries']) {
          await dispatch('colleges/fetchCollegesByMinistryId', { ministryId: userMinistry.id });
        }
        await dispatch('staffs/fetchStaffsByMinistryId', { ministryId });
        await dispatch('people/fetchRoles');
        await dispatch('currentUser/fetchCurrentUserGroupInvites');
        commit('SET_IS_INITIAL_DATA_LOADED', true);
      } catch (error) {
        commit('SET_IS_DATA_LOADING', false);
        console.log(error);
        throw error;
      }
    },
    async uploadImage({ commit }, data) {
      try {
        const options = {
          headers: { 'Content-Type': 'image/jpeg' },
        };
        const response = await http.post('image', data, options);
        return Promise.resolve(response.data);
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    },
  },
  modules: {
    auth: authModule,
    popups: popupsModule,
    currentUser: currentUserModule,
    ministries: ministriesModule,
    colleges: collegesModule,
    staffs: staffsModule,
    pagination: paginationModule,
    toast: toastModule,
    students: studentsModule,
    diocese: dioceseModule,
    people: peopleModule,
  },
});
