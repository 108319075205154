let toastMessages = [
  {
    id: 'test1',
    type: 'success',
    message: 'This is a success message!',
  },
  {
    id: 'test2',
    type: 'error',
    message: 'This is an error message!',
  },
  {
    id: 'test3',
    type: 'warning',
    message: 'This is a warning message!',
  },
  {
    id: 'test4',
    type: 'info',
    message: 'This is an info message!',
  },
];

const getInitialState = () => {
  // reset
  toastMessages = [];

  return {
    toastMessages,
  };
};

export default {
  namespaced: true,
  state: getInitialState,
  getters: {
    getMessages: (state) => {
      return state.toastMessages;
    },
  },
  mutations: {
    SET_INITIAL_STATE: (state) => {
      Object.assign(state, getInitialState());
    },
    SET_REMOVE_TOAST_MESSAGE: (state, id) => {
      const toastIdx = toastMessages.findIndex((toast) => toast.id === id);
      if (toastIdx >= 0) {
        state.toastMessages.splice(toastIdx, 1);
      }
    },
    SET_ADD_TOAST_MESSAGE: (state, messageObj) => {
      state.toastMessages.unshift(messageObj);
    },
  },
  actions: {
    removeToastMessage({ commit }, { id }) {
      commit('SET_REMOVE_TOAST_MESSAGE', id);
    },
    addToastMessage({ commit }, { id, type, message, duration }) {
      commit('SET_INITIAL_STATE');
      const messageObj = { id, type, message };
      commit('SET_ADD_TOAST_MESSAGE', messageObj);
      setTimeout(() => {
        commit('SET_REMOVE_TOAST_MESSAGE', messageObj.id);
      }, duration * 1000);
    },
  },
};
